import * as React from 'react';
import { useAppSelector } from '../../../app/hooks';

import { LOCAL_CONSTANT } from '../../Const';
import { Status, Wrapper } from "@googlemaps/react-wrapper";
import {
    Box,
    Modal,
    Button,
    Divider,
    Typography,
} from '@mui/material';
import {
    PlanAreaType,
    listInterFace,
    zIndexOrder,
} from "../Interface";
import { drawPlanArea } from "../viewData/PlanArea";
import {
    drawExistingSejConflictStore,
} from "../viewData/ExistingSejConflictStore";
import { useUtilityContext } from '../../../utility-provider';
import { EditConflict } from './EditConflict';
import {
    callApi,
    setSelectStoreLabel,
    setClickable,
    removeMarker,
    setSelectStoreNumber,
    drawCircle,
    business_type_value,
} from './AddConflictFunction';
import { useUserContext } from '../../../user-provider';
import {
    tradeAreaDensityFunc,
} from '../TradeArea/TradeAreaFunction';
import {
    SM_DRUG_MASTER_DATA,
} from '../../ResearchReport/Conflict';
import {
    GeoJsonPolygon,
    GeoJsonPolygon2googleMapsPolygonProperties
} from '../../../utility/turf';
import { booleanPointInPolygon } from '../../../utility/actionTurf';

const render = (status: Status) => {
    return <h1>{status}</h1>;
};
const baseStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "90%",
    height: "98%",
    maxHeight: "98%",
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
};
const infoBaseStyle = {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '30%',
    background: 'white',
    height: '100%',
    zIndex: '2',
    display: 'block',
}
const MapStyle = {
    width: '100%',
    height: '100%',
}
interface labelMarkerListType {
    key: string,
    marker: google.maps.Marker,
}
export interface addConflictModalProps {
    props: {
        open: boolean;
        handleModal: React.Dispatch<React.SetStateAction<boolean>>;
        center: any;
        distance: number;
        locationConflict: any;
        setReload: any;
        type: string;
    }
}

const AddConflictModal: React.FC<addConflictModalProps> = ({ props }) => {
    // 初期
    const utilityCtx = useUtilityContext();
    const user = useUserContext();
    const [open, setOpen] = React.useState(false);
    const [map, setMap] = React.useState<google.maps.Map>();
    const [mapDistance, setMapDistance] = React.useState(props.distance)
    const [circles, setCircles] = React.useState([]);
    // マップ中央
    const [center, setCenter] = React.useState<google.maps.LatLngLiteral>(props.center);
    // 候補地
    const [planAreaList, setPlanAreaList] = React.useState<PlanAreaType[]>();
    // 既存店競合店データ
    const [dataList, setDataList] = React.useState([]);
    const [existingConflictDataList, setExistingConflictDataList] = React.useState<listInterFace[]>([]);
    // MAP選択データ
    const [selectData, setSelectData] = React.useState<listInterFace | null>(null);
    // 選択マーカーリスト
    const [labelMarkerList, setLabelMarkerList] = React.useState<labelMarkerListType[]>([])
    // 既存店競合店登録リスト
    const [locationConflictList, setLocationConflictList] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState(false);

    // ボタンフラグ
    const [mapReloadButton, setMapReloadButton] = React.useState<boolean>(true)
    const [polyLineButton, setPolyLineButton] = React.useState<string>("")
    const [editPolyLineStatus, setEditPolyLineStatus] = React.useState("")
    const [commercialAreaDensity, setCommercialAreaDensity] = React.useState<string>("");
    const [targetRadius, setTargetRadius] = React.useState<string>("");
    const [markerList, setMarkerList] = React.useState<google.maps.Marker[]>([])
    const [includeCommercialArea, setIncludeCommercialArea] = React.useState<string>("")
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLocationBase = useAppSelector((state) => state.longPage.locationBase);
    //④商圏データRedux
    const listMpData = useAppSelector((state) => state.tradeArea.mpDataList);
    // 徒歩5分商圏円
    const locationWalk5minCircle = useAppSelector((state) => state.locationWalk5minCircle);

    // 閉じる
    const handleClose = () => {
        setOpen(false)
        props.handleModal(false)
        if (planAreaList) {
            setCenter({
                lat: planAreaList[0]['centerlat'],
                lng: planAreaList[0]['centerlon'],
            });
        }
    };

    React.useEffect(() => {
        if (props.open) {
            setOpen(props.open);
            setLoading(true)
        }
    }, [props.open])

    React.useEffect(() => {
        if (loading) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            if (!objLongPageParam.planAreaId.startsWith("X") && !objLongPageParam.planAreaId.startsWith("Y")) {
                // 候補地呼び出し
                callApi('planArea', {
                    id: objLongPageParam.planAreaId
                }, user)
                    .then((planAreaRes: any) => {
                        setPlanAreaList(planAreaRes)
                        setCenter({
                            lat: planAreaRes[0]['centerlat'],
                            lng: planAreaRes[0]['centerlon'],
                        })
                        // 既存店、競合店呼び出し
                        loadExistingSejConflictStores(planAreaRes[0]['centerlon'], planAreaRes[0]['centerlat'], mapDistance)
                        const result: string | undefined = tradeAreaDensityFunc('afterModified', listMpData)
                        if (result) {
                            setCommercialAreaDensity(result)
                            if (Number(result) >= 4) {
                                setTargetRadius('1')
                            } else if (Number(result) === 3) {
                                setTargetRadius('2')
                            } else {
                                setTargetRadius('3')
                            }
                        } else {
                            setCommercialAreaDensity("")
                            setTargetRadius("")
                        }
                    }).catch((e) => {
                        console.log(e)
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setLoading(false)
                    }).finally(() => {
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setLoading(false)
                    })
            } else {
                callApi('getLocationLinkByUsePlanAreaId', {
                    plan_area_id: objLongPageParam.planAreaId
                }, user)
                    .then((res: any) => {
                        if (res.getLocationLinkByUsePlanAreaId.length > 0) {
                            callApi('getExistingSejStore', {
                                store_code: res.getLocationLinkByUsePlanAreaId[0].store_code,
                            }, user)
                                .then((storeRes: any) => {
                                    setPlanAreaList(storeRes.getExistingSejStore)
                                    setCenter({
                                        lat: storeRes.getExistingSejStore[0]['centerlat'],
                                        lng: storeRes.getExistingSejStore[0]['centerlon'],
                                    })
                                    // 既存店、競合店呼び出し
                                    loadExistingSejConflictStores(storeRes.getExistingSejStore[0]['centerlon'], storeRes.getExistingSejStore[0]['centerlat'], mapDistance)
                                    const result: string | undefined = tradeAreaDensityFunc('afterModified', listMpData)
                                    if (result) {
                                        setCommercialAreaDensity(result)
                                        if (Number(result) >= 4) {
                                            setTargetRadius('1')
                                        } else if (Number(result) === 3) {
                                            setTargetRadius('2')
                                        } else {
                                            setTargetRadius('3')
                                        }
                                    } else {
                                        setCommercialAreaDensity("")
                                        setTargetRadius("")
                                    }
                                }).catch((e) => {
                                    console.log(e)
                                    if (utilityCtx.hideSpinner) {
                                        utilityCtx.hideSpinner();
                                    }
                                    setLoading(false)
                                }).finally(() => {
                                    if (utilityCtx.hideSpinner) {
                                        utilityCtx.hideSpinner();
                                    }
                                    setLoading(false)
                                })
                        }
                    }).catch((e) => {
                        console.log(e)
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setLoading(false)
                    }).finally(() => {
                        if (utilityCtx.hideSpinner) {
                            utilityCtx.hideSpinner();
                        }
                        setLoading(false)
                    })
            }
        }
    }, [loading]);

    const loadExistingSejConflictStores = (lng: number, lat: number, mapDistance: number) => {
        // 既存店、競合店呼び出し
        callApi('getExistingSejConflictStores', {
            plan_area_id: objLongPageParam.planAreaId,
            centerlon: lng,
            centerlat: lat,
            distance: mapDistance,
            excelSequence: objLocationBase.location_excel_sequence,
            type: props.type
        }, user)
            .then((res: any) => {
                if (props.type === 'sm-drug-master-data') {
                    const tmp: any = []
                    res.map((row: any) => {
                        if (row.id !== '') {
                            tmp.push(row)
                        }
                    })
                    setDataList(tmp)
                } else {
                    setDataList(res)
                }
            }).catch((e) => {
                console.log(e)
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setLoading(false)
            }).finally(() => {
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                setLoading(false)
            })
    }

    // マップ中央調整
    React.useEffect(() => {
        if (map) {
            const lanlot = map.getCenter()!.toJSON()
            if (lanlot.lat !== center.lat && lanlot.lng !== center.lng) {
                map.setCenter(center)
            }
        }
    }, [center])


    /**
     * 徒歩5分商圏円
     */
    React.useEffect(() => {
        if (map && locationWalk5minCircle.length > 0) {
            const polyData = JSON.parse(locationWalk5minCircle[0]['shape_feature']) as GeoJsonPolygon
            const poly = GeoJsonPolygon2googleMapsPolygonProperties(polyData);
            poly.setOptions({
                fillColor: "#dc143c",
                fillOpacity: 0,
                strokeColor: "#362CDF",
                strokeWeight: 1.0,
                visible: true,
                zIndex: zIndexOrder.Walk5minCircle,
            });
            poly.setMap(map!);
        }
    }, [map]);

    /**
     * 候補地処理
     */
    React.useEffect(() => {
        if (map && planAreaList && planAreaList.length !== 0) {
            // サークル描画
            setCircle(planAreaList[0].centerlat, planAreaList[0].centerlon)

            drawPlanArea(planAreaList, map);
        }
    }, [planAreaList]);

    /**
     * 既存店、競合店処理
     */
    React.useEffect(() => {
        if (markerList) {
            markerList.map((row: any) => {
                row.setMap(null)
            })
        }
        if (
            map &&
            planAreaList &&
            planAreaList.length !== 0 &&
            dataList &&
            dataList.length !== 0
        ) {
            const list = drawExistingSejConflictStore(
                dataList,
                map
            );

            // location_excel_03_dataの基づく番号付与
            const tmpLineDistance: any = []
            dataList.map((row: any) => {
                const location_excel_03_data = JSON.parse(row.location_excel_03_data)
                if (location_excel_03_data[0][0] !== null) {
                    tmpLineDistance.push({
                        data: row,
                        le: location_excel_03_data[0][0],
                    })
                }
                row["business_type"] = business_type_value(row)
            })

            const tmp = tmpLineDistance.sort((a: any, b: any) => Number(a.le.line_distance_m) - Number(b.le.line_distance_m))
            const tmpMarkerList: any = []
            let numberText = 1
            tmp.map((row: any) => {
                const marker = setSelectStoreNumber(map, row.data, numberText.toString())
                tmpMarkerList.push(marker)
                numberText = numberText + 1
            })
            setExistingConflictDataList(list)
            setMarkerList(tmpMarkerList)
        }
    }, [dataList]);

    /**
     * 既存店、競合店 clickイベント
     */
    const onClickExistingConflict = (
        e: google.maps.MapMouseEvent,
        storeData: any,
    ) => {
        let MAX_SELECTSTORE = 20
        if (props.type === SM_DRUG_MASTER_DATA) {
            MAX_SELECTSTORE = 10
        }
        if (locationConflictList.length >= MAX_SELECTSTORE) {
            if (utilityCtx.showSnackbar) {

                const MESSAGE_STRING = LOCAL_CONSTANT.CONFIRM_MESSAGE.LIMIT_OF_COMPETITOR_RESEARCH_LIST.replace("__MAX__", MAX_SELECTSTORE.toString())
                utilityCtx.showSnackbar("warning", MESSAGE_STRING);
            }
            return
        }
        let hasConflict = true
        props.locationConflict.map((row: any) => {
            if (row.conflict_id === storeData.key) {
                hasConflict = false
            }
        })
        let hasPointInPolygon = "0"
        if (locationWalk5minCircle.length > 0 && locationWalk5minCircle[0]['shape_feature']) {
            const polyData = JSON.parse(locationWalk5minCircle[0]['shape_feature']) as GeoJsonPolygon
            const poly = GeoJsonPolygon2googleMapsPolygonProperties(polyData);
            hasPointInPolygon = booleanPointInPolygon(poly, storeData.marker) ? "1" : "0"
        }
        setIncludeCommercialArea(hasPointInPolygon)
        setSelectData(storeData.data)
        if (hasConflict) {
            setClickable(existingConflictDataList, false);
            setMapReloadButton(false)
            clearMarker()
            const marker: google.maps.Marker = setSelectStoreLabel(map!, storeData.data, props.type, "#FF0000")
            const tmpMarker = labelMarkerList
            tmpMarker.push({
                key: storeData.key,
                marker: marker,
            })
            setLabelMarkerList(tmpMarker)
        } else {
            changeMarker({
                storeData: storeData.data
            }, "#FF0000")
        }
    }

    const setCircle = (centerlat: number, centerlon: number) => {
        circles.map((row: any) => {
            row.setMap(null)
        })
        const tmpCircle = drawCircle(centerlat, centerlon)

        tmpCircle.map((row: any) => {
            row.setMap(map!)
        })

        setCircles(tmpCircle)
    }

    const handleRouteMenu = (menu: string, params: any) => {
        switch (menu) {
            case 'cancelRoute':
                setSelectData(null)
                handleClose()
                break;
            case 'cancelAddRoute':
                setLabelMarkerList(removeMarker(labelMarkerList, params.storeData))
                setClickable(existingConflictDataList, true);
                setMapReloadButton(true)
                setSelectData(null)
                setIncludeCommercialArea("")
                break;
            case 'cancelEditRoute':
                changeMarker({
                    storeData: params.storeData.selectStore
                }, "#000000")
                setClickable(existingConflictDataList, true);
                setMapReloadButton(true)
                break;
            case 'handleAddRoute':
                changeMarker(params, "#000000")
                setClickable(existingConflictDataList, true);
                setMapReloadButton(true)
                break;
            case 'changeEditMode':
                changeMarker(params, "#FF0000")
                break;
            case 'drawPolyLine':
                setPolyLineButton("draw")
                setMapReloadButton(false)
                break;
            case 'cancelPolyLine':
                setEditPolyLineStatus('cancelPolyLine')
                setPolyLineButton("")
                setMapReloadButton(true)
                break;
            case 'redoPolyLine':
                setEditPolyLineStatus('redoPolyLine')
                break;
            case 'overlayPolyLine':
                setPolyLineButton("edit")
                setEditPolyLineStatus('overlayPolyLine')
                break;
            case 'resultPolyLine':
                setPolyLineButton("")
                setEditPolyLineStatus('resultPolyLine')
                setMapReloadButton(true)
                break;
            case 'setSelectStoreLabel':
                const marker: google.maps.Marker = setSelectStoreLabel(map!, params.storeData.data, props.type, "#000000")
                const tmpMarker = labelMarkerList
                tmpMarker.push({
                    key: params.storeData.key,
                    marker: marker,
                })
                setLabelMarkerList(tmpMarker)
                break;
            case 'removeList':
                setLabelMarkerList(removeMarker(labelMarkerList, params.storeData))
                setClickable(existingConflictDataList, true);
                break;
            case 'handleRegistRoute':
                setSelectData(null)
                handleClose()
                props.setReload(true)
                break;
            default:
                break
        }
    }

    const changeMarker = (params: any, marker_color: string) => {
        if (map) {
            let markerKey = params.storeData.id
            if (params.storeData.store_code !== "") {
                markerKey = params.storeData.store_code
            }
            clearMarker()
            labelMarkerList.map((val: any) => {
                if (val.key === markerKey) {
                    val.marker.setMap(null)
                    const marker: google.maps.Marker = setSelectStoreLabel(map, params.storeData, props.type, marker_color)
                    val.marker = marker
                    val.marker.setMap(map)
                }
            })
        }
    }

    const clearMarker = () => {
        labelMarkerList.map((val: any) => {
            existingConflictDataList.map((row: any) => {
                if (row.key === val.key) {
                    val.marker.setMap(null)
                    const marker: google.maps.Marker = setSelectStoreLabel(map!, row.data, props.type, "#000000")
                    val.marker = marker
                    val.marker.setMap(map!)
                }
            })
        })
    }


    const setClickEvent = () => {
        existingConflictDataList.map((store) => {
            // 一度クリア
            google.maps.event.clearListeners(store.marker, "click")
            // 再設定
            store.marker.addListener('click', (e: google.maps.MapMouseEvent) => onClickExistingConflict(
                e,
                store,
            ));
        })
    }

    const onIdle = (m: google.maps.Map) => {
        setCenter(m.getCenter()!.toJSON());
        // Map保存
        setMap(m)
    };

    const mapReload = () => {
        const zoom = map!.getZoom()
        const latLng = map!.getCenter()!.toJSON()
        if (zoom && latLng) {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            let mapDistance = LOCAL_CONSTANT.NUMBER.FOUR
            if (zoom >= 16) {
                mapDistance = LOCAL_CONSTANT.NUMBER.ONE
            } else if (zoom >= 15) {
                mapDistance = LOCAL_CONSTANT.NUMBER.TWO
            } else if (zoom >= 14) {
                mapDistance = LOCAL_CONSTANT.NUMBER.THREE
            }
            // 表示中のアイコン削除
            existingConflictDataList.map((row: any) => {
                row.marker.setMap(null)
            })
            labelMarkerList.map((row: any) => {
                row.marker.setMap(null)
            })
            loadExistingSejConflictStores(latLng.lng, latLng.lat, mapDistance)

            setMapDistance(mapDistance)
        }
    }

    return (
        <>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={baseStyle}>
                    <Box sx={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                    }}>
                        <InfoBase
                            style={infoBaseStyle}
                            plan_area_id={objLongPageParam.planAreaId}
                            selectData={selectData}
                            locationConflict={props.locationConflict}
                            existingConflictDataList={existingConflictDataList}
                            handleRouteMenu={handleRouteMenu}
                            map={map}
                            editPolyLineStatus={editPolyLineStatus}
                            setLocationConflictList={setLocationConflictList}
                            commercialAreaDensity={commercialAreaDensity}
                            targetRadius={targetRadius}
                            type={props.type}
                            includeCommercialArea={includeCommercialArea}
                        ></InfoBase>
                        <Box sx={{
                            left: '30%',
                            width: '70%',
                            height: '100%',
                            position: 'absolute',
                        }}>
                            <Box sx={{
                                position: 'absolute',
                                display: 'flex',
                                justifyContent: 'center',
                                margin: '0 auto',
                                zIndex: '10',
                                width: '50%',
                                top: '10px',
                                left: '0',
                                right: '0',
                                height: '40px',
                                border: '0'
                            }}>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={mapReload}
                                    sx={{ padding: '6px 50px', display: mapReloadButton ? 'block' : 'none' }}
                                >アイコン再読み込み</Button>
                                <Button
                                    variant="contained" color="error" size="small"
                                    onClick={(e) => { handleRouteMenu('cancelPolyLine', selectData) }}
                                    sx={{ padding: '6px 20px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                                >{LOCAL_CONSTANT.LABEL.CANCEL}</Button>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={(e) => { handleRouteMenu('redoPolyLine', selectData) }}
                                    sx={{ margin: '0 20px', padding: '6px 20px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                                >やり直し</Button>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={(e) => { handleRouteMenu('resultPolyLine', selectData) }}
                                    sx={{ margin: '0 20px', padding: '6px 20px', display: polyLineButton === 'edit' ? 'block' : 'none' }}
                                >{LOCAL_CONSTANT.LABEL.CONFIRMED}</Button>
                                <Button
                                    variant="contained" color="success" size="small"
                                    onClick={(e) => { handleRouteMenu('overlayPolyLine', selectData) }}
                                    sx={{ padding: '6px 20px', display: polyLineButton === 'draw' ? 'block' : 'none' }}
                                >仮確定</Button>
                            </Box>
                            <Wrapper
                                apiKey={"AIzaSyBH0T_KDXD97Dg4ZA0lPi_WRZQ-c09HNnY"} libraries={['drawing', 'marker']} render={render} version={'quarterly'}>
                                <MapBase
                                    style={MapStyle}
                                    center={center}
                                    onIdle={onIdle}
                                    onClickExistingConflict={onClickExistingConflict}
                                    setClickEvent={setClickEvent}
                                >
                                </MapBase>
                            </Wrapper>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </>
    );
}

interface InfoProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    plan_area_id: string;
    selectData: listInterFace | null;
    locationConflict: listInterFace[];
    existingConflictDataList: any;
    handleRouteMenu: any;
    map: google.maps.Map | undefined;
    editPolyLineStatus: string;
    setLocationConflictList: any;
    commercialAreaDensity: string;
    targetRadius: string;
    type: string;
    includeCommercialArea: string;
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
}

const InfoBase: React.FC<InfoProps> = ({
    style,
    plan_area_id,
    selectData,
    locationConflict,
    existingConflictDataList,
    handleRouteMenu,
    map,
    editPolyLineStatus,
    setLocationConflictList,
    commercialAreaDensity,
    targetRadius,
    type,
    includeCommercialArea,
    children,
    ...options
}) => {
    const setRouteMenu = (menu: string, selectData: any) => {
        handleRouteMenu(menu, selectData)
    }


    return (
        <>
            <Box sx={style}>
                <Box sx={{ height: '100%', padding: '4px' }}>
                    <Box sx={{ display: 'flex' }}>
                        <Typography sx={{ width: '40%', fontSize: '0.9rem', textAlign: 'center', margin: '0' }}>競合店選択</Typography>
                        <Typography sx={{ fontSize: '0.9rem', margin: '0' }}>商圏密度：{commercialAreaDensity}</Typography>
                        <Typography sx={{ fontSize: '0.9rem', margin: '0' }}>対象半径：{targetRadius}km</Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box id="addList-details">
                        {
                            <Box>
                                <EditConflict props={{
                                    selectStore: selectData,
                                    locationConflict: locationConflict,
                                    existingConflictDataList: existingConflictDataList,
                                    setRouteMenu: setRouteMenu,
                                    map: map,
                                    editPolyLineStatus: editPolyLineStatus,
                                    setLocationConflictList: setLocationConflictList,
                                    targetRadius: targetRadius,
                                    type: type,
                                    includeCommercialArea: includeCommercialArea,
                                }} />
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
        </>
    );
};

interface MapProps extends google.maps.MapOptions {
    style: { [key: string]: string };
    children?: React.ReactElement<google.maps.MarkerOptions>[] | React.ReactElement<google.maps.MarkerOptions>;
    onIdle?: (map: google.maps.Map) => void;
    onClickExistingConflict: any;
    setClickEvent: any;
}

const MapBase: React.FC<MapProps> = ({
    style,
    children,
    onIdle,
    onClickExistingConflict,
    setClickEvent,
    ...options
}) => {
    const ref = React.useRef<HTMLDivElement>(null);
    const [map, setMap] = React.useState<google.maps.Map>();

    React.useEffect(() => {
        if (ref.current && !map) {
            setMap(new window.google.maps.Map(ref.current, {
                mapId: 'e42bfbd48d30d1a0',
                disableDefaultUI: false,
                keyboardShortcuts: false,
                disableDoubleClickZoom: false,
                streetViewControl: false,
                zoomControl: true,
                scaleControl: true,
                clickableIcons: false,
                fullscreenControl: false,
                mapTypeControl: true,
                mapTypeControlOptions: {
                    position: google.maps.ControlPosition.TOP_RIGHT,
                },
                scrollwheel: true,
                center: options.center,
                zoom: 16,
                // mapTypeId: google.maps.MapTypeId.SATELLITE,
            }));
        }
    }, [ref, map]);

    React.useEffect(() => {
        if (map) {
            ["idle"].forEach((eventName) =>
                google.maps.event.clearListeners(map, eventName)
            );
            if (onIdle) {
                map.addListener("idle", () => onIdle(map));
            }
            if (onClickExistingConflict) {
                setClickEvent()
            }
        }
    }, [map, onIdle, onClickExistingConflict]);

    return (
        <>
            <div ref={ref} style={style} />
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    // set the map prop on the child component
                    // @ts-ignore
                    return React.cloneElement(child, { map });
                }
            })}
        </>
    );
};


export default AddConflictModal;