import * as React from "react";

import { useUserContext } from "../../user-provider";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { setSaveStatus } from "../../features/longPageSlice";
import { setTrafficVolume } from "../../features/trafficVolumeSlice";
import { setN15AddMarket } from "../../features/n15AddMarketSlice";
import {
    setObjLocationTraffic5mcut,
    setInitSelectList,
    setSelectList,
    setSelectList2,
} from "../../features/locationTraffic5mcutSlice";
import { LOCAL_CONSTANT } from "../Const";
import {
    Typography,
    Box,
    Button,
    Checkbox,
    FormLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
} from "@mui/material";
import { locationTrafficTotal } from "../subPage/TrafficVolume/TrafficVolumeFunction";
import TrafficVolumeCar from "../subPage/TrafficVolume/TrafficVolumeCar";
import TrafficVolumePeople from "../subPage/TrafficVolume/TrafficVolumePeople";
import OtherTraffic from "../subPage/TrafficVolume/OtherTraffic";
import AddTrafficVolume from "../subPage/TrafficVolume/AddTrafficVolume";
import { useUtilityContext } from "../../utility-provider";
import {
    getLocationTraffic,
    getN15AddMarket,
    getLongPageData,
} from "../../data/DataManage";

import UploadIcon from "@mui/icons-material/Upload";
import {
    getFileData,
    uploadFile,
    deleteUploadFile,
    getFileMetadata,
} from "../../utility/storage";
import { postPython } from "../../api/ApiCall";
import MessageDialog from "../components/MessageDialog";
import { getAccessToken } from "../Common";
import { setPermissionFunc } from "../components/PermissionFunc";
import { DeleteConfirmationModal } from "../ResearchReport/DeleteConfirmationModal";
import DeleteIcon from "@mui/icons-material/Delete";

export interface resultDialogProps {
    props: {};
}

const list = () => {
    return [
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.IN_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN,
        },
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.OUT_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT,
        },
        {
            header:
                LOCAL_CONSTANT.LABEL.PEOPLE_TRAFFIC +
                "（" +
                LOCAL_CONSTANT.LABEL.IN_OUT_FIVE +
                "）",
            target: LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT,
        },
    ];
};

const TEMPLATE_FILE_NAME = "/template/5m外算入根拠_テンプレート.xlsx";
const UPLOAD_FILE_NAME = "upload_5m外算入根拠.xlsx";
const DEFAULT_IMAGE_01 = "/image/TrafficVolume/image001.png";
const DEFAULT_IMAGE_02 = "/image/TrafficVolume/image002.png";

interface locationTrafficType {
    keyName: String;
    data: any;
}

const TrafficVolume: React.FC<resultDialogProps> = ({ props }) => {
    const [open, setOpen] = React.useState<boolean>(false);
    const [target, setTarget] = React.useState<string>("");
    const utilityCtx = useUtilityContext();
    const [reload, setReload] = React.useState<boolean>(false);
    const [locationTraffic, setLocationTraffic] = React.useState<
        locationTrafficType[]
    >([]);
    const [n15AddMarketData, setN15AddMarketData] = React.useState<any>([]);
    const [locationTraffic5mcutData, setLocationTraffic5mcutData] =
        React.useState<any>([]);
    const [dataCar, setDataCar] = React.useState([]);
    const [dataPeople1, setDataPeople1] = React.useState([]);
    const [dataPeople2, setDataPeople2] = React.useState([]);
    const [dataPeople3, setDataPeople3] = React.useState([]);
    const [dataPeople4, setDataPeople4] = React.useState([]);
    const [nightRatio, setNightRatio] = React.useState(0);
    const [holidaysRatio, setHolidaysRatio] = React.useState(0);
    const [nightRatioValue, setNightRatioValue] = React.useState(0);
    const [holidaysRatioValue, setHolidaysRatioValue] = React.useState(0);

    //パラメータオブジェクト
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    const objLongPageSaveStatus = useAppSelector(
        (state) => state.longPage.saveStatus
    );
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    const objTrafficVolume = useAppSelector((state) => state.trafficVolume);
    const objN15AddMarket = useAppSelector((state) => state.n15AddMarket);
    const objLocationTraffic5mcut = useAppSelector(
        (state) => state.locationTraffic5mcut.obj
    );
    const dispatch = useAppDispatch();

    const [file, setFile] = React.useState<File | null>(null);
    const [image001, setImage001] = React.useState<string>(DEFAULT_IMAGE_01);
    const [image002, setImage002] = React.useState<string>(DEFAULT_IMAGE_02);
    const [layoutImgPath, setLayoutImgPath] = React.useState<String>("");
    const [photoImgPath, setPhotoImgPath] = React.useState<String>("");
    const [checkList, setCheckList] = React.useState([
        {
            name: 0,
            key: "check_planting",
            checked: false,
            label: "店舗入口ドア予定位置前に植栽等障害物がない",
        },
        {
            name: 1,
            key: "check_visibility",
            checked: false,
            label: "5ｍ外算入範囲から新店頭看板・入口ドアが視認可能​",
        },
        {
            name: 2,
            key: "check_stairs",
            checked: false,
            label: "店舗入口ドア予定位置まで階段等段差がない",
        },
        {
            name: 3,
            key: "check_count",
            checked: false,
            label: "道路の反対側をカウントする場合、車交通量が少なく店舗への往来に支障がない事",
        },
    ]);
    const [isChecked, setIsChecked] = React.useState<boolean>(false);
    const [messageDialogOpen, setMessageDialogOpen] =
        React.useState<boolean>(false);
    const [userType, setUserType] = React.useState("");
    const user = useUserContext();
    const [userData, setUserData] = React.useState<any>({});
    // 追加ボタン
    const [addDisabled, setAddDisabled] = React.useState<boolean>(true);
    const [uploadDisabled, setUploadDisabled] = React.useState<boolean>(true);

    const [separateInvestigation, setSeparateInvestigation] =
        React.useState<any>();
    const [separateValue, setSeparateValue] = React.useState<string>("0");
    const [peopleSeparateValue, setPeopleSeparateValue] =
        React.useState<string>("0");
    const [cutSurveyValue, setCutSurveyValue] = React.useState<string>("0");
    const [yesnoneKana, setYesnoneKana] = React.useState<any>();

    React.useEffect(() => {
        const res: any = localStorage.getItem("codeMaster");
        if (res) {
            const d = JSON.parse(res);
            setSeparateInvestigation(d["separate_investigation"]);
            setYesnoneKana(d["common_yesnone_kana"]);
            dispatch(setInitSelectList(d["others_facility"])); //その他施設 施設プルダウンリスト初期値
            dispatch(setSelectList(d["others_facility"])); //その他施設 3行目施設プルダウンリスト
            dispatch(setSelectList2(d["others_facility"])); //その他施設 4行目施設プルダウンリスト
        }
    }, []);

    //ユーザー取得タイミングによる画面制御
    React.useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserType(JSON.parse(user.userData || "null").user_type);
            setUserData(JSON.parse(user.userData));
        }
    }, [user]);

    //ボタンの活性か非活性
    /* React.useEffect(() => {
    if (userData && objLocationBase.plan_area_id !== '') {
      // 追加ボタン
      if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
        setAddDisabled(false)
      }
      // 5m外アップロードボタン
      if (userData.job_category_code_OA === '360' && objLocationBase.task_id === 'A001') {
        setUploadDisabled(false)
      }
    }
  }, [userData, objLocationBase]) */
    const [message, setMessage] = React.useState<string>("");
    const [hasButton, setHasButton] = React.useState<boolean>(false);
    const [deleteConfModalOpen, setDeleteConfModalOpen] = React.useState(false);

    const onClickHandle = (target: string) => {
        setTarget(target);
        setOpen(true);
    };

    const callApi = async (target: string) => {
        if (target === "locationTraffic") {
            // テスト中固定値
            return await getLocationTraffic({
                mode: "getLocationTraffic",
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
            });
        }
        if (target === "getN15AddMarket") {
            return await getN15AddMarket({
                mode: "getN15AddMarket",
                plan_area_id: objLongPageParam.planAreaId,
                location_type: objLocationBase.location_type,
            });
        }
        if (target === "getLocationTraffic5mcut") {
            const param: any = [
                {
                    mode: "getLocationTraffic5mcut",
                    plan_area_id: objLongPageParam.planAreaId,
                    branch: objLongPageParam.branch,
                },
            ];
            return await getLongPageData(param);
        }
    };

    React.useEffect(() => {
        setLocationTraffic(objTrafficVolume);
    }, [objTrafficVolume]);

    React.useEffect(() => {
        if (objN15AddMarket.length > 0) {
            setNightRatio(
                objN15AddMarket[0]["population_ordinary_night_others"] /
                    objN15AddMarket[0]["population_ordinary_daytime_others"]
            );
            setHolidaysRatio(
                objN15AddMarket[0]["population_holiday_others"] /
                    objN15AddMarket[0]["population_ordinary_others"]
            );
            //人流表示(%)
            setNightRatioValue(
                Math.round(
                    objN15AddMarket[0]["population_ordinary_night_others"]
                ) /
                    Math.round(
                        objN15AddMarket[0]["population_ordinary_daytime_others"]
                    )
            );
            setHolidaysRatioValue(
                Math.round(objN15AddMarket[0]["population_holiday_others"]) /
                    Math.round(objN15AddMarket[0]["population_ordinary_others"])
            );
        }
    }, [objN15AddMarket]);

    React.useEffect(() => {
        if (locationTraffic5mcutData) {
            dataSet(locationTraffic5mcutData);
        }
    }, [locationTraffic5mcutData]);

    const dataSet = (lTraffic5Data: any) => {
        // 画像セット
        const bucket_path =
            process.env.REACT_APP_GOOGLE_STORAGE +
            "/" +
            process.env.REACT_APP_FIREBASE_STORAGE_BUCKET +
            "/" +
            objLongPageParam.planAreaId +
            "/" +
            objLongPageParam.branch;
        const access_token = getAccessToken();
        if (lTraffic5Data.layout_img_path) {
            setImage001(
                bucket_path + lTraffic5Data.layout_img_path + access_token
            );
            setLayoutImgPath(lTraffic5Data.layout_img_path);
        } else {
            setImage001(DEFAULT_IMAGE_01);
            setLayoutImgPath("");
        }
        if (lTraffic5Data.photo_img_path) {
            setImage002(
                bucket_path + lTraffic5Data.photo_img_path + access_token
            );
            setPhotoImgPath(lTraffic5Data.photo_img_path);
        } else {
            setImage002(DEFAULT_IMAGE_02);
            setPhotoImgPath("");
        }
        // checkList
        if (lTraffic5Data.check_planting === "1") {
            checkList[0]["checked"] = true;
        }
        if (lTraffic5Data.check_visibility === "1") {
            checkList[1]["checked"] = true;
        }
        if (lTraffic5Data.check_stairs === "1") {
            checkList[2]["checked"] = true;
        }
        if (lTraffic5Data.check_count === "1") {
            checkList[3]["checked"] = true;
        }
        setIsChecked(!isChecked);
    };

    const dispatchLTraffic5Data = (labelName: string, value: string) => {
        if (objLocationTraffic5mcut) {
            dispatch(
                setObjLocationTraffic5mcut({
                    ...objLocationTraffic5mcut,
                    [labelName]: value,
                })
            );
            // 更新フラグ
            dispatch(
                setSaveStatus({
                    ...objLongPageSaveStatus,
                    ["TrafficVolume"]: true,
                })
            );
        }
    };

    React.useEffect(() => {
        setSeparateValue(objLocationTraffic5mcut.car_another_survey);
        setPeopleSeparateValue(objLocationTraffic5mcut.people_another_survey);
        setCutSurveyValue(objLocationTraffic5mcut.cut_survey);
        setLocationTraffic5mcutData(objLocationTraffic5mcut);
    }, [objLocationTraffic5mcut]);

    React.useEffect(() => {
        if (reload) {
            trafficReload();
        }
    }, [reload]);

    const trafficReload = () => {
        try {
            if (utilityCtx.showSpinner) {
                utilityCtx.showSpinner();
            }
            // 通行量 呼び出し
            callApi("locationTraffic")
                .then((res: any) => {
                    dispatch(setTrafficVolume(locationTrafficTotal(res)));
                    callApi("getN15AddMarket")
                        .then((res: any) => {
                            dispatch(setN15AddMarket(res));
                            setReload(false);
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                        })
                        .catch((e) => {
                            console.log(e);
                            setReload(false);
                            if (utilityCtx.hideSpinner) {
                                utilityCtx.hideSpinner();
                            }
                        });
                })
                .catch((e) => {
                    console.log(e);
                    setReload(false);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                });
        } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            setReload(false);
        }
    };

    React.useEffect(() => {
        const car_TRAFFICTYPE_0: any = [];
        const car_TRAFFICTYPE_1: any = [];
        const car_TRAFFICTYPE_2: any = [];
        const car_TRAFFICTYPE_4: any = [];
        const people_TRAFFICTYPE_6: any = [];
        const people_TRAFFICTYPE_7: any = [];
        const people_TRAFFICTYPE_8: any = [];
        const people_TRAFFICTYPE_9: any = [];
        const people_TRAFFICTYPE_10: any = [];
        const people_TRAFFICTYPE_12: any = [];
        const people_TRAFFICTYPE_13: any = [];
        if (locationTraffic) {
            locationTraffic.map((row: any) => {
                switch (row.traffic_type) {
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal:
                        car_TRAFFICTYPE_0.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal:
                        car_TRAFFICTYPE_1.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal:
                        car_TRAFFICTYPE_2.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal:
                        car_TRAFFICTYPE_4.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal:
                        people_TRAFFICTYPE_6.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal:
                        people_TRAFFICTYPE_7.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal:
                        people_TRAFFICTYPE_8.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal:
                        people_TRAFFICTYPE_9.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal:
                        people_TRAFFICTYPE_10.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal:
                        people_TRAFFICTYPE_12.push(row);
                        break;
                    case LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal:
                        people_TRAFFICTYPE_13.push(row);
                        break;
                }
            });
        }
        let carTmpList: any = [];
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_0.typeVal,
            data: car_TRAFFICTYPE_0,
        });
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_1.typeVal,
            data: car_TRAFFICTYPE_1,
        });
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_2.typeVal,
            data: car_TRAFFICTYPE_2,
        });
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_3.typeVal,
            data: car_TRAFFICTYPE_1,
        });
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_4.typeVal,
            data: car_TRAFFICTYPE_4,
        });
        carTmpList.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_5.typeVal,
            data: car_TRAFFICTYPE_1,
        });
        setDataCar(carTmpList);
        const peopleTmpList1: any = [];
        const peopleTmpList2: any = [];
        const peopleTmpList3: any = [];
        const peopleTmpList4: any = [];

        peopleTmpList1.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_6.typeVal,
            data: people_TRAFFICTYPE_6,
        });
        peopleTmpList1.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_8.typeVal,
            data: people_TRAFFICTYPE_8,
        });
        setDataPeople1(peopleTmpList1);

        peopleTmpList2.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_7.typeVal,
            data: people_TRAFFICTYPE_7,
        });
        peopleTmpList2.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_9.typeVal,
            data: people_TRAFFICTYPE_9,
        });
        peopleTmpList2.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_12.typeVal,
            data: people_TRAFFICTYPE_12,
        });
        setDataPeople2(peopleTmpList2);

        peopleTmpList3.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_10.typeVal,
            data: people_TRAFFICTYPE_10,
        });
        peopleTmpList3.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_11.typeVal,
            data: people_TRAFFICTYPE_8,
        });
        setDataPeople3(peopleTmpList3);

        peopleTmpList4.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_13.typeVal,
            data: people_TRAFFICTYPE_13,
        });
        peopleTmpList4.push({
            keyName: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.name,
            trafficType: LOCAL_CONSTANT.TRAFFICVOLUME.TRAFFICTYPE_14.typeVal,
            data: people_TRAFFICTYPE_8,
        });
        setDataPeople4(peopleTmpList4);
    }, [locationTraffic]);

    const ratio = (key: string) => {
        let ratioValue = 0;
        if (key === "night") {
            ratioValue = Math.round(nightRatio * 10000) / 100;
        } else if (key === "holidays") {
            ratioValue = Math.round(holidaysRatio * 10000) / 100;
        }

        return ratioValue.toString() + "%";
    };

    const fileDownload = () => {
        downloadFirebase(TEMPLATE_FILE_NAME);
    };

    const excelDownload = () => {
        if (locationTraffic5mcutData && locationTraffic5mcutData.excel_path) {
            downloadFirebase(
                objLongPageParam.planAreaId +
                    "/" +
                    objLongPageParam.branch +
                    "/" +
                    locationTraffic5mcutData.excel_path
            );
        }
    };

    const downloadFirebase = (path: string) => {
        getFileData(path).then((url: any) => {
            const link = document.createElement("a");
            link.href = url;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        });
    };

    const handleDeleteConfModalOpen = () => {
        setDeleteConfModalOpen(true);
    };

    //ファイル削除
    const handleDeleteChange = () => {
        deleteFile();
    };

    const handleClickFile = () => {
        const fileElem = document.getElementById("fileElem");
        if (fileElem) {
            fileElem.click();
        }
    };

    const handleChangeFile = (newFile: any) => {
        setFile(newFile.target.files[0]);
        newFile.target.value = "";
    };

    React.useEffect(() => {
        if (file) {
            fileUpload(file);
        }
    }, [file]);

    const fileUpload = (file: any) => {
        const params = apiParams("create");
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.CONVERT_EXCEL);
                setMessageDialogOpen(true);
                const uploadResult = await uploadFile(
                    file,
                    objLongPageParam.planAreaId +
                        "/" +
                        objLongPageParam.branch +
                        "/excel/" +
                        UPLOAD_FILE_NAME
                );
                const result = await postPython(params);
                setFile(null);
                setMessage(
                    LOCAL_CONSTANT.CONFIRM_MESSAGE.COMPLETE_CONVERT_EXCEL
                );
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(
                    LOCAL_CONSTANT.CONFIRM_MESSAGE.UNCOMPLETE_CONVERT_EXCEL
                );
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const apiParams = (mode: string) => {
        const userData = JSON.parse(user.userData);

        return {
            mode: "regist",
            endPoint: "/excel2image",
            query: {
                mode: mode,
                prop_site_no: objLongPageParam.planAreaId,
                file_name: UPLOAD_FILE_NAME,
                branch: objLongPageParam.branch,
                layout_img_path: layoutImgPath,
                photo_img_path: photoImgPath,
                check_planting: checkList[0]["checked"] === true ? "1" : "0",
                check_visibility: checkList[1]["checked"] === true ? "1" : "0",
                check_stairs: checkList[2]["checked"] === true ? "1" : "0",
                check_count: checkList[3]["checked"] === true ? "1" : "0",
                car_another_survey: objLocationTraffic5mcut.car_another_survey,
                people_another_survey:
                    objLocationTraffic5mcut.people_another_survey,
                cut_survey: objLocationTraffic5mcut.cut_survey,
                others_facility_1: objLocationTraffic5mcut.others_facility_1,
                others_name_1: objLocationTraffic5mcut.others_name_1,
                others_distance_1: objLocationTraffic5mcut.others_distance_1,
                others_value_1: objLocationTraffic5mcut.others_value_1,
                others_facility_2: objLocationTraffic5mcut.others_facility_2,
                others_name_2: objLocationTraffic5mcut.others_name_2,
                others_distance_2: objLocationTraffic5mcut.others_distance_2,
                others_value_2: objLocationTraffic5mcut.others_value_2,
                others_facility_3: objLocationTraffic5mcut.others_facility_3,
                others_name_3: objLocationTraffic5mcut.others_name_3,
                others_distance_3: objLocationTraffic5mcut.others_distance_3,
                others_value_3: objLocationTraffic5mcut.others_value_3,
                others_facility_4: objLocationTraffic5mcut.others_facility_4,
                others_name_4: objLocationTraffic5mcut.others_name_4,
                others_distance_4: objLocationTraffic5mcut.others_distance_4,
                others_value_4: objLocationTraffic5mcut.others_value_4,
                user_data: {
                    update_top_department: userData.top_department_code_OA,
                    update_department: userData.department_code_OA,
                    update_job: userData.job_category_code_OA,
                    update_mail: userData.email.toString(),
                    update_stuff_number: userData.firebase_id,
                    update_name: userData.user_name,
                },
            },
        };
    };

    const deleteFile = () => {
        const params = apiParams("delete");
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.DELETE_FILE);
                setMessageDialogOpen(true);
                // ファイル削除
                const path =
                    "/" +
                    objLongPageParam.planAreaId +
                    "/" +
                    objLongPageParam.branch +
                    "/";
                const deleteFile = await getFileMetadata(
                    path + locationTraffic5mcutData.excel_path
                );
                await deleteUploadFile(
                    deleteFile,
                    path + locationTraffic5mcutData.excel_path
                );
                const layoutImgFile = await getFileMetadata(
                    path + locationTraffic5mcutData.layout_img_path
                );
                await deleteUploadFile(
                    layoutImgFile,
                    path + locationTraffic5mcutData.layout_img_path
                );
                const photoImgFile = await getFileMetadata(
                    path + locationTraffic5mcutData.photo_img_path
                );
                await deleteUploadFile(
                    photoImgFile,
                    path + locationTraffic5mcutData.photo_img_path
                );
                // python処理
                await postPython(params);
                setFile(null);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.SUCCESS_DELETE_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            } catch (e) {
                console.log(e);
                setMessage(LOCAL_CONSTANT.CONFIRM_MESSAGE.ERROR_DELETE_FILE);
                setHasButton(true);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
            }
        })();
    };

    const getLocationTraffic5mcut = () => {
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        try {
            // 通行量 呼び出し
            callApi("getLocationTraffic5mcut")
                .then((res: any) => {
                    if (res) {
                        dataSet(res.getLocationTraffic5mcut[0]);
                        dispatch(
                            setObjLocationTraffic5mcut(
                                res.getLocationTraffic5mcut[0]
                            )
                        );
                    }
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    if (utilityCtx.hideSpinner) {
                        utilityCtx.hideSpinner();
                    }
                });
        } catch (e) {
            console.log(e);
            if (utilityCtx.hideSpinner) {
                utilityCtx.hideSpinner();
            }
            setReload(false);
        }
    };

    const locationTraffic5mcutDisabled = () => {
        let disabled = true;

        if (objLocationTraffic5mcut && objLocationTraffic5mcut.excel_path) {
            disabled = setPermissionFunc(
                userType,
                objLocationBase.task_id,
                LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                "excel_dl_button"
            );
        }

        return disabled;
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        checkList[Number(event.target.name)]["checked"] = event.target.checked;
        dispatchLTraffic5Data(
            checkList[Number(event.target.name)]["key"],
            event.target.checked ? "1" : "0"
        );
        setIsChecked(!isChecked);
    };

    const setChecked = (target: number) => {
        return checkList[Number(target)]["checked"];
    };
    const closeMessageDialog = () => {
        setMessage("");
        setHasButton(false);
        setMessageDialogOpen(false);
        getLocationTraffic5mcut();
    };

    const handleSelectedChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        if (labelName === "car_another_survey") {
            setSeparateValue(e.target.value);
        } else if (labelName === "people_another_survey") {
            setPeopleSeparateValue(e.target.value);
        }
        if (objLocationTraffic5mcut) {
            dispatch(
                setObjLocationTraffic5mcut({
                    ...objLocationTraffic5mcut,
                    [labelName]: e.target.value,
                })
            );
            // 更新フラグ
            dispatch(
                setSaveStatus({
                    ...objLongPageSaveStatus,
                    ["TrafficVolume"]: true,
                })
            );
        }
    };

    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <MessageDialog
                props={{
                    open: messageDialogOpen,
                    handleModal: closeMessageDialog,
                    body: message,
                    hasButton: hasButton,
                    buttonBody: LOCAL_CONSTANT.LABEL.CLOSE,
                }}
            />
            <Box>
                <h3>{LOCAL_CONSTANT.LABEL.CAR_TRAFFIC}</h3>
            </Box>
            <Box sx={{ display: "flex", padding: "10px" }}>
                <FormLabel sx={{ padding: "14px 0" }}>
                    別途調査必要時（該当を選択）
                </FormLabel>
                <Select
                    onChange={(e) =>
                        handleSelectedChange(e, "car_another_survey")
                    }
                    value={separateValue}
                    disabled={setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                        "car_another_survey"
                    )}
                >
                    {separateInvestigation?.map((row: any) => (
                        <MenuItem key={row.code_value} value={row.code_value}>
                            {row.code_display}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box>
                <Button
                    sx={{ margin: "20px 0" }}
                    variant="contained"
                    color="success"
                    size="small"
                    disabled={setPermissionFunc(
                        userType,
                        objLocationBase.task_id,
                        LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                        "add_car"
                    )}
                    onClick={() => {
                        onClickHandle(LOCAL_CONSTANT.VALUE.ADD_CAR);
                    }}
                >
                    {LOCAL_CONSTANT.LABEL.ADD}
                </Button>
            </Box>
            {dataCar.map((row: any) => (
                <Box sx={{ width: "96%" }} key={row.trafficType}>
                    {/* ユーザ種別がRFCの場合は
                            ・夜（モバ空）　20:00　～　21:30
                            ・土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30
                        を非表示
                    */}
                    {!(
                        (userType === "z003" &&
                            row.trafficType.toString() === "3") ||
                        (userType === "z003" &&
                            row.trafficType.toString() === "5")
                    ) && (
                        <TrafficVolumeCar
                            props={{
                                keyName: row.keyName,
                                trafficType: row.trafficType,
                                trafficData: row.data,
                                setReload: setReload,
                                nightRatio: nightRatio,
                                holidaysRatio: holidaysRatio,
                                nightRatioValue: nightRatioValue,
                                holidaysRatioValue: holidaysRatioValue,
                                userType: userType,
                            }}
                        />
                    )}
                </Box>
            ))}
            {list().map((listData: any) => (
                <Box
                    sx={{ width: "96%" }}
                    key={listData.target}
                    id={listData.target + "-Container"}
                >
                    <Box
                        key={listData.target + "-header"}
                        id={listData.target + "-header"}
                    >
                        {listData.target ===
                            LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN && (
                            <Box>
                                <h3>{listData.header}</h3>
                            </Box>
                        )}
                        {(listData.target ===
                            LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_OUT ||
                            listData.target ===
                                LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN_N_OUT) &&
                            objLocationTraffic5mcut.cut_survey === "1" && (
                                <Box>
                                    <h3>{listData.header}</h3>
                                </Box>
                            )}
                        {listData.target ===
                            LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN && (
                            <Box sx={{ display: "flex", padding: "10px" }}>
                                <FormLabel sx={{ padding: "14px 0" }}>
                                    別途調査必要時（該当を選択）
                                </FormLabel>
                                <Select
                                    onChange={(e) =>
                                        handleSelectedChange(
                                            e,
                                            "people_another_survey"
                                        )
                                    }
                                    value={peopleSeparateValue}
                                    disabled={setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                                        "people_another_survey"
                                    )}
                                >
                                    {separateInvestigation?.map((row: any) => (
                                        <MenuItem
                                            key={row.code_value}
                                            value={row.code_value}
                                        >
                                            {row.code_display}
                                        </MenuItem>
                                    ))}
                                </Select>
                                <FormLabel sx={{ padding: "14px" }}>
                                    5m外通行量調査
                                </FormLabel>
                                <Select
                                    onChange={(e) =>
                                        handleSelectedChange(e, "cut_survey")
                                    }
                                    value={cutSurveyValue}
                                    disabled={setPermissionFunc(
                                        userType,
                                        objLocationBase.task_id,
                                        LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                                        "cut_survey"
                                    )}
                                >
                                    {yesnoneKana?.map((row: any) => (
                                        <MenuItem
                                            key={row.code_value}
                                            value={row.code_value}
                                        >
                                            {row.code_display}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        )}
                        {listData.target ===
                            LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN && (
                            <Button
                                sx={{ margin: "20px 0" }}
                                variant="contained"
                                color="success"
                                size="small"
                                disabled={setPermissionFunc(
                                    userType,
                                    objLocationBase.task_id,
                                    LOCAL_CONSTANT.NAVI.TRAFFIC_VOLUME,
                                    "add_people"
                                )}
                                onClick={() => {
                                    onClickHandle(
                                        LOCAL_CONSTANT.VALUE.ADD_PEOPLE
                                    );
                                }}
                            >
                                {LOCAL_CONSTANT.LABEL.ADD}
                            </Button>
                        )}
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                            key={listData.target + "-left-right"}
                        >
                            <Box
                                sx={{ width: "49%" }}
                                key={listData.target + "-left"}
                                id={listData.target + "-left"}
                            >
                                {dataPeople1.map((row: any) => (
                                    <TrafficVolumePeople
                                        key={row.trafficType + "-left"}
                                        props={{
                                            keyName: row.keyName,
                                            trafficType: row.trafficType,
                                            trafficData: row.data,
                                            target: listData.target,
                                            setReload: setReload,
                                            nightRatio: nightRatio,
                                            holidaysRatio: holidaysRatio,
                                            nightRatioValue: nightRatioValue,
                                            holidaysRatioValue:
                                                holidaysRatioValue,
                                            userType: userType,
                                        }}
                                    />
                                ))}
                            </Box>
                            <Box
                                sx={{ width: "49%" }}
                                key={listData.target + "-right"}
                                id={listData.target + "-right"}
                            >
                                {dataPeople2.map((row: any) => (
                                    <TrafficVolumePeople
                                        key={row.trafficType + "-right"}
                                        props={{
                                            keyName: row.keyName,
                                            trafficType: row.trafficType,
                                            trafficData: row.data,
                                            target: listData.target,
                                            setReload: setReload,
                                            nightRatio: nightRatio,
                                            holidaysRatio: holidaysRatio,
                                            nightRatioValue: nightRatioValue,
                                            holidaysRatioValue:
                                                holidaysRatioValue,
                                            userType: userType,
                                        }}
                                    />
                                ))}
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                            key={listData.target + "-n"}
                        >
                            {dataPeople3.map((row: any) => (
                                <Box
                                    sx={{ width: "49%" }}
                                    key={row.trafficType}
                                >
                                    {/* ユーザ種別がRFCの場合は夜（モバ空）　20:00　～　21:30を非表示 */}
                                    {!(
                                        userType === "z003" &&
                                        row.trafficType.toString() === "11"
                                    ) && (
                                        <TrafficVolumePeople
                                            props={{
                                                keyName: row.keyName,
                                                trafficType: row.trafficType,
                                                trafficData: row.data,
                                                target: listData.target,
                                                setReload: setReload,
                                                nightRatio: nightRatio,
                                                holidaysRatio: holidaysRatio,
                                                nightRatioValue:
                                                    nightRatioValue,
                                                holidaysRatioValue:
                                                    holidaysRatioValue,
                                                userType: userType,
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                            }}
                            key={listData.target + "-holyDays"}
                        >
                            {dataPeople4.map((row: any) => (
                                <Box
                                    sx={{ width: "49%" }}
                                    key={row.trafficType}
                                >
                                    {/* ユーザ種別がRFCの場合は土曜、休祝日又は行楽地のトップシーズン（モバ空）　10:30　～　16:30を非表示 */}
                                    {!(
                                        userType === "z003" &&
                                        row.trafficType.toString() === "14"
                                    ) && (
                                        <TrafficVolumePeople
                                            props={{
                                                keyName: row.keyName,
                                                trafficType: row.trafficType,
                                                trafficData: row.data,
                                                target: listData.target,
                                                setReload: setReload,
                                                nightRatio: nightRatio,
                                                holidaysRatio: holidaysRatio,
                                                nightRatioValue:
                                                    nightRatioValue,
                                                holidaysRatioValue:
                                                    holidaysRatioValue,
                                                userType: userType,
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Box>
                        {listData.target ===
                            LOCAL_CONSTANT.VALUE.PEOPLE_TARGET_IN &&
                            objLocationTraffic5mcut.cut_survey === "1" && (
                                <Box>
                                    <Box>
                                        <h3>5m外算入根拠</h3>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                            width: "560px",
                                            maxWidth: "560px",
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                "> button": { margin: "10px" },
                                            }}
                                        >
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={fileDownload}
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .TRAFFIC_VOLUME,
                                                    "template_dl_button"
                                                )}
                                            >
                                                {
                                                    LOCAL_CONSTANT.LABEL
                                                        .TEMPLATE_DL
                                                }
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="success"
                                                size="small"
                                                onClick={excelDownload}
                                                disabled={locationTraffic5mcutDisabled()}
                                            >
                                                {LOCAL_CONSTANT.LABEL.EXCEL_DL}
                                            </Button>
                                        </Box>
                                        <Box>
                                            <input
                                                id="fileElem"
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={handleChangeFile}
                                                accept=".xls,.xlsx"
                                            />
                                            <Button
                                                sx={{ color: "black" }}
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .TRAFFIC_VOLUME,
                                                    "uploadButton"
                                                )}
                                            >
                                                <UploadIcon
                                                    fontSize="large"
                                                    onClick={handleClickFile}
                                                />
                                            </Button>
                                            <Button
                                                sx={{ color: "black" }}
                                                disabled={locationTraffic5mcutDisabled()}
                                            >
                                                <DeleteIcon
                                                    fontSize="large"
                                                    onClick={
                                                        handleDeleteConfModalOpen
                                                    }
                                                />
                                            </Button>
                                        </Box>
                                    </Box>
                                    <Box sx={{ display: "flex" }}>
                                        <Box sx={{ margin: "10px" }}>
                                            <Typography>
                                                配置図または航空写真
                                                <br />
                                                （5m内外の計測範囲を記載）
                                            </Typography>
                                            <Box
                                                sx={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <img
                                                    src={image001}
                                                    style={{
                                                        width: "560px",
                                                        maxWidth: "560px",
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        <Box sx={{ margin: "10px" }}>
                                            <Typography>
                                                写真（障害物有無がわかる）
                                                <br />
                                                （5m内外の計測範囲を記載）
                                            </Typography>
                                            <Box
                                                sx={{
                                                    border: "1px solid black",
                                                }}
                                            >
                                                <img
                                                    src={image002}
                                                    style={{
                                                        width: "560px",
                                                        maxWidth: "560px",
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Typography>
                                            5m外歩行者TR算入確認
                                        </Typography>
                                    </Box>
                                    {checkList.map((row: any) => (
                                        <Typography key={row.key}>
                                            <Checkbox
                                                checked={setChecked(
                                                    row["name"]
                                                )}
                                                name={row["name"]}
                                                onChange={handleChange}
                                                disabled={setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .TRAFFIC_VOLUME,
                                                    "traffic5mcut"
                                                )}
                                            ></Checkbox>
                                            {row.label}
                                        </Typography>
                                    ))}
                                </Box>
                            )}
                    </Box>
                </Box>
            ))}
            {/*<Box><Typography>{LOCAL_CONSTANT.LABEL.OTHER}</Typography></Box>*/}
            <OtherTraffic props={{ userType: userType }} />
            <AddTrafficVolume
                props={{
                    open: open,
                    handleModal: setOpen,
                    target: target,
                    setReload: setReload,
                }}
            />

            {/* 削除確認モーダル */}
            <DeleteConfirmationModal
                modalOpen={deleteConfModalOpen}
                setModalOpen={setDeleteConfModalOpen}
                deleteMethod={handleDeleteChange}
            />
        </div>
    );
};

export default TrafficVolume;
