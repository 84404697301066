import {
    Box,
    FormControl,
    Grid,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
    Typography,
    OutlinedInput,
    styled,
    IconButton,
} from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { apiRequest } from "../../api/ApiCall";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    initDonationObj,
    DonationTypeList,
    DonationBooleanTypeList,
    DonationStringTypeList,
    initDonationCommentTextFieldObj,
    initDonationReadOnlyObj,
    initDonationShowEditIconObj,
    initDonationShowIconObj,
} from "../../data/type";
import { setSaveStatus } from "../../features/longPageSlice";
import { setObjDonation } from "../../features/donationSlice";
import { useUserContext } from "../../user-provider";
import { useUtilityContext } from "../../utility-provider";
import { LOCAL_CONSTANT } from "../Const";
import InputWithIcons from "../components/InputWithIcons";
import { inactiveTextSx } from "../components/TextFieldStyle";
import { setPermissionFunc } from "../components/PermissionFunc";
import ChatBubbleIcon from "@mui/icons-material/ChatBubble";
import EditIcon from "@mui/icons-material/Edit";
import DeficiencyCommentRegistModal from "../components/DeficiencyCommentRegistModal";
import {
    chatBubbleCircleSx,
    chatBubbleIconSx,
    editIconCircleSx,
    iconHover,
} from "../components/ChatBubbleAndEditIconStyle";

export interface resultDialogProps {
    props: {};
}

const table = {
    marginBottom: "20px",
};

const cellLabelStyle = {
    width: "100px",
    border: "none",
};

const bottomCellLabelStyle = {
    width: "100px",
    borderBottom: "solid 1px #bcbcbc",
};

const titleLabelStyle = {
    marginBottm: "20px",
};

const descriptionLabelStyle = {
    fontSize: "12px",
};

const tableHeaderLabelCell = {
    width: "5%",
    textAlign: "center",
    border: "none",
};

const tableHeaderCell = {
    width: "220px",
    textAlign: "center",
    border: "none",
};

const tableCell = {
    width: "220px",
    border: "none",
};

const inactiveSelectStyle = {
    textAlign: "center", //プルダウン値 有無を中央寄せ
    backgroundColor: "rgba(0, 0, 0, 0.06)",
};

const selectStyle = (isUserType: boolean) => {
    return {
        textAlign: "center",
        backgroundColor: isUserType ? "rgba(0, 0, 0, 0.06)" : "#FFFCD6",
    };
};

//アスタリスク
const RedAsterisk = styled("span")({
    color: "red",
    marginLeft: "4px",
    display: "flex",
    alignItems: "center",
});

const Donation: React.FC<resultDialogProps> = ({ props }) => {
    //Redux Storeからデータ取得
    const objDonation = useAppSelector((state) => state.donation.obj);
    //ロングページオブジェクト 候補地NOと枝を取得
    const objLongPageParam = useAppSelector((state) => state.longPage.param);
    //ステータス管理sequenceとタスクIDを取得するため
    const objLocationBase = useAppSelector(
        (state) => state.longPage.locationBase
    );
    //パラメーターオブジェクト
    const objLongPageSaveStatus = useAppSelector(
        (state) => state.longPage.saveStatus
    );

    //寄付きページの項目値をReduxへ登録する前に保存
    const [donation, setDonation] = useState<DonationTypeList>(initDonationObj);
    //側道A列の非活性
    const [isSideWayA, setIsSideWayA] = useState<boolean>(false);
    //側道B列の非活性
    const [isSideWayB, setIsSideWayB] = useState<boolean>(false);
    //裏道の非活性
    const [isBackstreet, setBackstreet] = useState<boolean>(false);
    //コードマスタ
    const [commonYesnone, setCommonYesnone] = useState<any>(); //有無

    const dispatch = useAppDispatch();
    const [userType, setUserType] = useState("");
    const user = useUserContext();
    const utilityCtx = useUtilityContext();

    //不備コメントモーダル項目名
    const [columnNameStr, setColumnNameStr] = useState<string>("");
    //不備コメントモーダルラベル名
    const [labelNameStr, setLabelNameStr] = useState<string>("");
    //不備コメントモーダル開閉フラグ
    const [deficiencyComentModalFlag, setDeficiencyComentModalFlag] =
        useState(false);
    //アイコン全体の表示非表示のステータス
    const [donationShowIconsObj, setDonationShowIconsObj] =
        useState<DonationBooleanTypeList>(initDonationShowIconObj);
    //不備コメントモーダル内、TextFieldのコメント
    const [donationCommentTextFieldObj, setDonationCommentTextFieldObj] =
        useState<DonationStringTypeList>(initDonationCommentTextFieldObj);
    //項目値を編集可能にするステータスの型定義
    const [donationReadOnlyObj, setDonationReadOnlyObj] =
        useState<DonationBooleanTypeList>(initDonationReadOnlyObj);

    //インプット
    const handleIntOrDecimalInputChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        const targetValue = e.target.value;
        const donationValue = donation[labelName];

        const regexRange0To9 = /^(|0|[0-9])$/; //入力規則:整数 範囲:0～9
        const regexRange0To5 = /^(|0|[0-5])$/; //入力規則:整数 範囲:0～5
        const regexRange0To200 = /^(|0|200|1\d{2}|[1-9]?\d)$/; //入力規則:整数 範囲:0～200
        const regexRange0To99999 = /^(|0|[1-9][0-9]{0,4})$/; //入力規則:整数 範囲:0～99999
        const regexRange0To99Point9 =
            /^(|0|0\.|0\.[0-9]|[1-9][0-9]{0,1}(\.[0-9]{0,1})?|)$/; //入力規則:小数点数 範囲:0～99.9
        const regexRange0To999Point9 =
            /^(|0|0\.|0\.[0-9]|[1-9][0-9]{0,2}(\.[0-9]{0,1})?|)$/; //入力規則:小数点数 範囲:0～999.9
        const regexRange0TO9999Point99 =
            /^(|0|0\.|0\.0|0\.[0-9][0-9]?|[1-9][0-9]{0,3}(\.[0-9]{0,2})?)$/; //入力規則:小数点数 範囲:0～9999.99

        //入力規則:整数 範囲:0～9
        const regexRange0To9Label = [
            "drivingLanePositiveFrontage", //走行車線 正 前面
            "drivingLanePositiveSideWayA", //走行車線 正 側道A
            "drivingLanePositiveSideWayB", //走行車線 正 側道B
            "drivingLanePositiveBackstreet", //走行車線 正 裏道
            "drivingLaneOppositeFrontage", //走行車線 反 前面
            "drivingLaneOppositeSideWayA", //走行車線 反 側道A
            "drivingLaneOppositeSideWayB", //走行車線 反 側道B
            "drivingLaneOppositeBackstreet", //走行車線 反 裏道
        ];

        //入力規則:整数 範囲:0～5
        const regexRange0To5Label = [
            "visibilityFactorSignPost", //視界性 サインポール 前面
            "visibilityFactorSignPost2", //視界性 サインポール 側道A
            "visibilityFactorSignPost3", //視界性 サインポール 側道B
            "visibilityFactorSignPost4", //視界性 サインポール 裏道
        ];

        //入力規則:整数 範囲:0～200
        const regexRange0To200Label = [
            "trafficSignalEtcForwardDistance", //信号・踏切 先 距離 前面
            "trafficSignalEtcForwardDistance2", //信号・踏切 先 距離 側道A
            "trafficSignalEtcForwardDistance3", //信号・踏切 先 距離 側道B
            "trafficSignalEtcForwardDistance4", //信号・踏切 先 距離 裏道
            "trafficSignalEtcFrontLocationDistance", //信号・踏切 手前 距離 前面
            "trafficSignalEtcFrontLocationDistance2", //信号・踏切 手前 距離 側道A
            "trafficSignalEtcFrontLocationDistance3", //信号・踏切 手前 距離 側道B
            "trafficSignalEtcFrontLocationDistance4", //信号・踏切 手前 距離 裏道
        ];

        //入力規則:小数点数 範囲:0～99.9
        const regexRange0To99Point9Label = [
            "roadWidthDoor", //車道幅員 ドア 前面
            "roadWidthDoor2", //車道幅員 ドア 側道A
            "roadWidthDoor3", //車道幅員 ドア 側道B
            "roadWidthDoor4", //車道幅員 ドア 裏道
            "roadWidthForward", //車道幅員 先 前面
            "roadWidthForward2", //車道幅員 先 側道A
            "roadWidthForward3", //車道幅員 先 側道B
            "roadWidthForward4", //車道幅員 先 裏道
            "roadWidthFrontLocation", //車道幅員 手前 前面
            "roadWidthFrontLocation2", //車道幅員 手前 側道A
            "roadWidthFrontLocation3", //車道幅員 手前 側道B
            "roadWidthFrontLocation4", //車道幅員 手前 裏道
            "slopeClimb", //坂道(長さ1m当たり) 登 前面
            "slopeClimb2", //坂道(長さ1m当たり) 登 側道A
            "slopeClimb3", //坂道(長さ1m当たり) 登 側道B
            "slopeClimb4", //坂道(長さ1m当たり) 登 裏道
            "slopeDescend", //坂道(長さ1m当たり) 降 前面
            "slopeDescend2", //坂道(長さ1m当たり) 降 側道A
            "slopeDescend3", //坂道(長さ1m当たり) 降 側道B
            "slopeDescend4", //坂道(長さ1m当たり) 降 裏道
        ];

        //入力規則:小数点数 範囲:0～999.9
        const regexRange0To999Point9Label = [
            "medianStripHeight", //中央分離帯 高さ 前面
            "medianStripHeight2", //中央分離帯 高さ 側道A
            "medianStripHeight3", //中央分離帯 高さ 側道B
            "medianStripHeight4", //中央分離帯 高さ 裏道
            "sidewalkHeight", //歩道 高さ 前面
            "sidewalkHeight2", //歩道 高さ 側道A
            "sidewalkHeight3", //歩道 高さ 側道B
            "sidewalkHeight4", //歩道 高さ 裏道
            "visibilityFactorBuilding", //視界性 建物 前面
            "visibilityFactorBuilding2", //視界性 建物 側道A
            "visibilityFactorBuilding3", //視界性 建物 側道B
            "visibilityFactorBuilding4", //視界性 建物 裏道
            "parkingTangentLineFrontage", //駐車場接線 前面
            "parkingTangentLineSideWayA", //駐車場接線 側道A
            "parkingTangentLineSideWayB", //駐車場接線 側道B
            "parkingTangentLineBackstreet", //駐車場接線 裏道
            "no1EntranceFrontage", //出入口 No.1 進入口 前面
            "no1EntranceSideWayA", //出入口 No.1 進入口 側道A
            "no1EntranceSideWayB", //出入口 No.1 進入口 側道B
            "no1EntranceBackstreet", //出入口 No.1 進入口 裏道
            "no1DepthFrontage", //出入口 No.1 奥行き 前面
            "no1DepthSideWayA", //出入口 No.1 奥行き 側道A
            "no1DepthSideWayB", //出入口 No.1 奥行き 側道B
            "no1DepthBackstreet", //出入口 No.1 奥行き 裏道
            "no2EntranceFrontage", //出入口 No.2 進入口 前面
            "no2EntranceSideWayA", //出入口 No.2 進入口 側道A
            "no2EntranceSideWayB", //出入口 No.2 進入口 側道B
            "no2EntranceBackstreet", //出入口 No.2 進入口 裏道
            "no2DepthFrontage", //出入口 No.2 奥行き 前面
            "no2DepthSideWayA", //出入口 No.2 奥行き 側道A
            "no2DepthSideWayB", //出入口 No.2 奥行き 側道B
            "no2DepthBackstreet", //出入口 No.2 奥行き 裏道
        ];

        //入力規則:整数 範囲:0～99999
        const regexRange0To99999Label = [
            "parkingCapacity" /* 有効駐車場面積 駐車可能台数 */,
        ];

        //入力規則:小数点数 範囲:0～9999.99
        const regexRange0TO9999Point99Label = [
            "parkingArea" /* 有効駐車場面積 面積 ㎡ */,
        ];

        //入力判定
        if (regexRange0To9Label.includes(labelName)) {
            if (regexRange0To9.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0To5Label.includes(labelName)) {
            if (regexRange0To5.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0To200Label.includes(labelName)) {
            if (regexRange0To200.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0To99999Label.includes(labelName)) {
            if (regexRange0To99999.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0To99Point9Label.includes(labelName)) {
            if (regexRange0To99Point9.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0To999Point9Label.includes(labelName)) {
            if (regexRange0To999Point9.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        } else if (regexRange0TO9999Point99Label.includes(labelName)) {
            if (regexRange0TO9999Point99.test(targetValue)) {
                setDonation({ ...donation, [labelName]: targetValue });
            } else {
                setDonation({ ...donation, [labelName]: donationValue });
            }
        }

        // 更新フラグ
        if (!objLongPageSaveStatus.Donation)
            dispatch(
                setSaveStatus({ ...objLongPageSaveStatus, ["Donation"]: true })
            );
    };

    //Redux プルダウン
    const handleSelectChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        //プルダウンで無を選択した場合、その下の項目を空白に変更
        if (e.target.value === "0") {
            switch (labelName) {
                case "medianStripSelect":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            medianStripHeight: "",
                        })
                    );
                    break;
                case "medianStripSelect2":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            medianStripHeight2: "",
                        })
                    );
                    break;
                case "medianStripSelect3":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            medianStripHeight3: "",
                        })
                    );
                    break;
                case "medianStripSelect4":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            medianStripHeight4: "",
                        })
                    );
                    break;
                case "sidewalkSelect":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            sidewalkHeight: "",
                        })
                    );
                    break;
                case "sidewalkSelect2":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            sidewalkHeight2: "",
                        })
                    );
                    break;
                case "sidewalkSelect3":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            sidewalkHeight3: "",
                        })
                    );
                    break;
                case "sidewalkSelect4":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            sidewalkHeight4: "",
                        })
                    );
                    break;
                case "trafficSignalEtcForwardSelect":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcForwardDistance: "",
                        })
                    );
                    break;
                case "trafficSignalEtcForwardSelect2":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcForwardDistance2: "",
                        })
                    );
                    break;
                case "trafficSignalEtcForwardSelect3":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcForwardDistance3: "",
                        })
                    );
                    break;
                case "trafficSignalEtcForwardSelect4":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcForwardDistance4: "",
                        })
                    );
                    break;
                case "trafficSignalEtcFrontLocationSelect":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcFrontLocationDistance: "",
                        })
                    );
                    break;
                case "trafficSignalEtcFrontLocationSelect2":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcFrontLocationDistance2: "",
                        })
                    );
                    break;
                case "trafficSignalEtcFrontLocationSelect3":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcFrontLocationDistance3: "",
                        })
                    );
                    break;
                case "trafficSignalEtcFrontLocationSelect4":
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                            trafficSignalEtcFrontLocationDistance4: "",
                        })
                    );
                    break;
                default:
                    dispatch(
                        setObjDonation({
                            ...objDonation,
                            [labelName]: e.target.value,
                        })
                    ); //プルダウンデータ更新
            }
        } else {
            // プルダウンデータ更新
            dispatch(
                setObjDonation({ ...objDonation, [labelName]: e.target.value })
            );
        }
        // 更新フラグ
        if (!objLongPageSaveStatus.Donation)
            dispatch(
                setSaveStatus({ ...objLongPageSaveStatus, ["Donation"]: true })
            );
    };

    //Redux プルダウン
    const handleYearMonthSelectChange = (
        e: SelectChangeEvent<string>,
        labelName: string
    ) => {
        // プルダウンデータ更新 Redux Storeデータ変更
        dispatch(
            setObjDonation({ ...objDonation, [labelName]: e.target.value })
        );
        // 更新フラグ
        if (!objLongPageSaveStatus.Donation)
            dispatch(
                setSaveStatus({ ...objLongPageSaveStatus, ["Donation"]: true })
            );
    };

    //入力不可のインプットをクリックしたときアイコンを表示
    const handleInputClick = (labelName: string) => {
        setDonationShowIconsObj({ ...donationShowIconsObj, [labelName]: true });
    };

    //吹き出しをクリックしたとき、モーダル画面を開く
    const handleChatBubbleClick = (columnName: string, labelName: string) => {
        setColumnNameStr(columnName);
        setLabelNameStr(labelName);
        setDeficiencyComentModalFlag(true);
        setDonationCommentTextFieldObj({
            ...donationCommentTextFieldObj,
            [labelName]: "",
        }); //テキストフィールドのコメントを初期化
    };

    //鉛筆をクリックしたとき、Input編集可
    const handleEditIconClick = (labelName: string) => {
        if (
            setPermissionFunc(
                userType,
                objLocationBase.task_id,
                LOCAL_CONSTANT.NAVI.DONATION,
                labelName
            ) === false
        ) {
            //falseにすると、Input編集可
            setDonationReadOnlyObj({
                ...donationReadOnlyObj,
                [labelName]: false,
            });
        }
    };

    //不備コメントモーダル内でのコメント取得
    const handleCommentChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        labelName: string
    ) => {
        setDonationCommentTextFieldObj({
            ...donationCommentTextFieldObj,
            [labelName]: e.target.value,
        });
    };

    //不備コメントモーダル内の保存ボタンをクリックしたときコメントを保存
    const handleSaveComment = (columnName: string, labelName: string) => {
        //不備コメントを登録
        const params = {
            mode: "regist",
            endPoint: "/location/Deficiency/v1/regist",
            query: {
                plan_area_id: objLongPageParam.planAreaId,
                branch: objLongPageParam.branch,
                status_seq: objLocationBase.status_seq,
                table_name: "location_donation",
                column_name: columnName,
                comment: donationCommentTextFieldObj[labelName],
            },
        };
        if (utilityCtx.showSpinner) {
            utilityCtx.showSpinner();
        }
        (async () => {
            try {
                await apiRequest(params);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setDonationShowIconsObj({
                    ...donationShowIconsObj,
                    [labelName]: false,
                });
            } catch (e) {
                console.log(e);
                if (utilityCtx.hideSpinner) {
                    utilityCtx.hideSpinner();
                }
                //不備コメントモーダルを閉じる
                setDeficiencyComentModalFlag(false);
                //アイコンを非表示
                setDonationShowIconsObj({
                    ...donationShowIconsObj,
                    [labelName]: false,
                });
            }
        })();
    };

    // インプットからフォーカスがずれたら、インプット編集不可とアイコンを非表示にする
    const handleInputBlur = (
        e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
        labelName: string
    ) => {
        //Reduxへ保存
        dispatch(setObjDonation({ ...donation, [labelName]: e.target.value }));

        // インプット編集不可
        setDonationReadOnlyObj({ ...donationReadOnlyObj, [labelName]: true });

        //インプットからフォーカスが移動する先がnullの場合アイコンを閉じる
        if (!e.relatedTarget) {
            setDonationShowIconsObj({
                ...donationShowIconsObj,
                [labelName]: false,
            });
        }

        //インプットからフォーカスが移動する先がインプットタグの場合アイコンを閉じる
        if (e.relatedTarget && e.relatedTarget.tagName === "INPUT") {
            setDonationShowIconsObj({
                ...donationShowIconsObj,
                [labelName]: false,
            });
        }
    };

    //コードマスタ取得
    useEffect(() => {
        const res: any = localStorage.getItem("codeMaster");
        if (res) {
            const d = JSON.parse(res);
            //コードマスタ
            setCommonYesnone(d["common_yesnone"]); //有無
        }
    }, []);

    //ユーザー取得タイミングによる画面制御
    useEffect(() => {
        if (JSON.parse(user.userData || "null") !== null) {
            setUserType(JSON.parse(user.userData || "null").user_type);
        }
    }, [user]);

    useEffect(() => {
        //走行車線 正 反 の側道Aが「0」か「空」の場合 非活性
        if (
            (objDonation.drivingLanePositiveSideWayA === "0" ||
                objDonation.drivingLanePositiveSideWayA === "") &&
            (objDonation.drivingLaneOppositeSideWayA === "0" ||
                objDonation.drivingLaneOppositeSideWayA === "")
        ) {
            setIsSideWayA(true);
        } else {
            setIsSideWayA(false);
        }

        //走行車線 正 反 の側道Bが「0」か「空」の場合 非活性
        if (
            (objDonation.drivingLanePositiveSideWayB === "0" ||
                objDonation.drivingLanePositiveSideWayB === "") &&
            (objDonation.drivingLaneOppositeSideWayB === "0" ||
                objDonation.drivingLaneOppositeSideWayB === "")
        ) {
            setIsSideWayB(true);
        } else {
            setIsSideWayB(false);
        }

        //走行車線 正 反 の裏道が「0」か「空」の場合 非活性
        if (
            (objDonation.drivingLanePositiveBackstreet === "0" ||
                objDonation.drivingLanePositiveBackstreet === "") &&
            (objDonation.drivingLaneOppositeBackstreet === "0" ||
                objDonation.drivingLaneOppositeBackstreet === "")
        ) {
            setBackstreet(true);
        } else {
            setBackstreet(false);
        }
    }, [
        objDonation.drivingLanePositiveSideWayA,
        objDonation.drivingLaneOppositeSideWayA,
        objDonation.drivingLanePositiveSideWayB,
        objDonation.drivingLaneOppositeSideWayB,
        objDonation.drivingLanePositiveBackstreet,
        objDonation.drivingLaneOppositeBackstreet,
    ]);

    useEffect(() => {
        //ReduxをuseStateに保存
        setDonation(objDonation);
    }, [objDonation]);

    return (
        <div style={{ width: "100%", margin: "30px" }}>
            <Grid container spacing={1} maxWidth="lg">
                <Grid item xs={12}>
                    <Table sx={table}>
                        <TableBody>
                            <TableRow>
                                <TableCell
                                    sx={tableHeaderLabelCell}
                                ></TableCell>
                                <TableCell
                                    sx={tableHeaderLabelCell}
                                ></TableCell>
                                <TableCell sx={tableHeaderCell}>
                                    <InputLabel>
                                        {LOCAL_CONSTANT.LABEL.FRONT}
                                    </InputLabel>
                                </TableCell>
                                <TableCell sx={tableHeaderCell}>
                                    <InputLabel>
                                        {LOCAL_CONSTANT.LABEL.SIDE_A}
                                    </InputLabel>
                                </TableCell>
                                <TableCell sx={tableHeaderCell}>
                                    <InputLabel>
                                        {LOCAL_CONSTANT.LABEL.SIDE_B}
                                    </InputLabel>
                                </TableCell>
                                <TableCell sx={tableHeaderCell}>
                                    <InputLabel>
                                        {LOCAL_CONSTANT.LABEL.BACK}
                                    </InputLabel>
                                </TableCell>
                            </TableRow>

                            {/* 走行車線 正*/}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                走行車線
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　右左折専用車線は含めず
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>正</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLanePositiveFrontage"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_positive_frontage",
                                            labelName:
                                                "drivingLanePositiveFrontage",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLanePositiveSideWayA"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_positive_sideway_a",
                                            labelName:
                                                "drivingLanePositiveSideWayA",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLanePositiveSideWayB"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_positive_sideway_b",
                                            labelName:
                                                "drivingLanePositiveSideWayB",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLanePositiveBackstreet"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_positive_backstreet",
                                            labelName:
                                                "drivingLanePositiveBackstreet",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>

                            {/* 走行車線 反 */}
                            <TableRow sx={bottomCellLabelStyle}>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>反</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLaneOppositeFrontage"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_opposite_frontage",
                                            labelName:
                                                "drivingLaneOppositeFrontage",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLaneOppositeSideWayA"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_opposite_sideway_a",
                                            labelName:
                                                "drivingLaneOppositeSideWayA",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLaneOppositeSideWayB"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_opposite_sideway_b",
                                            labelName:
                                                "drivingLaneOppositeSideWayB",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "drivingLaneOppositeBackstreet"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "driving_lane_opposite_backstreet",
                                            labelName:
                                                "drivingLaneOppositeBackstreet",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.LANE,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>

                            {/* 中央分離帯 有無 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                中央分離帯
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　ガードレール・植栽高さ等を含む
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>有無</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "medianStripSelect"
                                                    )
                                                }
                                                value={
                                                    objDonation.medianStripSelect
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "medianStripSelect"
                                                          )
                                                        : donationReadOnlyObj.medianStripSelect
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "medianStripSelect"
                                                          )
                                                        : donationReadOnlyObj.medianStripSelect
                                                }
                                            >
                                                {commonYesnone?.map(
                                                    (row: any) => (
                                                        <MenuItem
                                                            key={row.code_value}
                                                            value={
                                                                row.code_value
                                                            }
                                                        >
                                                            {row.code_display}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "medianStripSelect"
                                                    )}
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "median_strip_select",
                                                            "medianStripSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "medianStripSelect"
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "medianStripSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayA === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    value={
                                                        objDonation.medianStripSelect2
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayA === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "medianStripSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.medianStripSelect2
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect2"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect2
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect2"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect2
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayA &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "medianStripSelect2"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "median_strip_select2",
                                                            "medianStripSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayA &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "medianStripSelect2"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayA}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "medianStripSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayB === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "medianStripSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.medianStripSelect3
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayB === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "medianStripSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.medianStripSelect3
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect3"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect3
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect3"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect3
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayB &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "medianStripSelect3"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "median_strip_select3",
                                                            "medianStripSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayB &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "medianStripSelect3"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayB}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "medianStripSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isBackstreet === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "medianStripSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.medianStripSelect4
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isBackstreet === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "medianStripSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.medianStripSelect4
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect4"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect4
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "medianStripSelect4"
                                                              )
                                                            : donationReadOnlyObj.medianStripSelect4
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isBackstreet &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "medianStripSelect4"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "median_strip_select4",
                                                            "medianStripSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isBackstreet &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "medianStripSelect4"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isBackstreet}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "medianStripSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>

                            {/* 中央分離帯 高さ */}
                            <TableRow sx={bottomCellLabelStyle}>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>高さ</InputLabel>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: !(
                                                !setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "medianStripHeight"
                                                ) &&
                                                objDonation.medianStripSelect ===
                                                    "1"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "median_strip_height",
                                            labelName: "medianStripHeight",
                                            startAdornment: "",
                                            endAdornment: "cm",
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height2",
                                                labelName: "medianStripHeight2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "medianStripHeight2"
                                                    ) &&
                                                    objDonation.medianStripSelect2 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height2",
                                                labelName: "medianStripHeight2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height3",
                                                labelName: "medianStripHeight3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "medianStripHeight3"
                                                    ) &&
                                                    objDonation.medianStripSelect3 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height3",
                                                labelName: "medianStripHeight3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height4",
                                                labelName: "medianStripHeight4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "medianStripHeight4"
                                                    ) &&
                                                    objDonation.medianStripSelect4 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "median_strip_height4",
                                                labelName: "medianStripHeight4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 歩道 有無*/}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                歩道
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　ガードレール・植栽高さ等を含む
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>有無</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "sidewalkSelect"
                                                    )
                                                }
                                                value={
                                                    objDonation.sidewalkSelect
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "sidewalkSelect"
                                                          )
                                                        : donationReadOnlyObj.sidewalkSelect
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "sidewalkSelect"
                                                          )
                                                        : donationReadOnlyObj.sidewalkSelect
                                                }
                                            >
                                                {commonYesnone?.map(
                                                    (row: any) => (
                                                        <MenuItem
                                                            key={row.code_value}
                                                            value={
                                                                row.code_value
                                                            }
                                                        >
                                                            {row.code_display}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "sidewalkSelect"
                                                    )}
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "sidewalk_select",
                                                            "sidewalkSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "sidewalkSelect"
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "sidewalkSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayA === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect2
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayA === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect2
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect2"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect2
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect2"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect2
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayA &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "sidewalkSelect2"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "sidewalk_select2",
                                                            "sidewalkSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayA &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "sidewalkSelect2"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayA}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "sidewalkSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayB === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect3
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayB === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect3
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect3"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect3
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect3"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect3
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayB &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "sidewalkSelect3"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "sidewalk_select3",
                                                            "sidewalkSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            isSideWayB
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayB}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "sidewalkSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isBackstreet === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect4
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isBackstreet === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "sidewalkSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.sidewalkSelect4
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect4"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect4
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "sidewalkSelect4"
                                                              )
                                                            : donationReadOnlyObj.sidewalkSelect4
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isBackstreet &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "sidewalkSelect4"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "sidewalk_select4",
                                                            "sidewalkSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isBackstreet &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "sidewalkSelect4"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isBackstreet}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "sidewalkSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>

                            {/* 歩道 高さ */}
                            <TableRow sx={bottomCellLabelStyle}>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>高さ</InputLabel>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: !(
                                                !setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "sidewalkHeight"
                                                ) &&
                                                objDonation.sidewalkSelect ===
                                                    "1"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "sidewalk_height",
                                            labelName: "sidewalkHeight",
                                            startAdornment: "",
                                            endAdornment: "cm",
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height2",
                                                labelName: "sidewalkHeight2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "sidewalkHeight2"
                                                    ) &&
                                                    objDonation.sidewalkSelect2 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height2",
                                                labelName: "sidewalkHeight2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height3",
                                                labelName: "sidewalkHeight3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "sidewalkHeight3"
                                                    ) &&
                                                    objDonation.sidewalkSelect3 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height3",
                                                labelName: "sidewalkHeight3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height4",
                                                labelName: "sidewalkHeight4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "sidewalkHeight4"
                                                    ) &&
                                                    objDonation.sidewalkSelect4 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "sidewalk_height4",
                                                labelName: "sidewalkHeight4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 車道幅員 ドア */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={3}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                車幅幅員
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　片側最大2車線迄
                                            </InputLabel>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　信号、交差点の中は"0"
                                            </InputLabel>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　全幅5m未満の場合全幅記入
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>ドア</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "roadWidthDoor"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "road_width_door",
                                            labelName: "roadWidthDoor",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door2",
                                                labelName: "roadWidthDoor2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthDoor2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door2",
                                                labelName: "roadWidthDoor2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door3",
                                                labelName: "roadWidthDoor3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthDoor3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door3",
                                                labelName: "roadWidthDoor3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door4",
                                                labelName: "roadWidthDoor4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthDoor4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "road_width_door4",
                                                labelName: "roadWidthDoor4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 車道幅 先 */}
                            <TableRow>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　信号、交差点の中は"0"</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>先</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "roadWidthForward"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "road_width_forward",
                                            labelName: "roadWidthForward",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward2",
                                                labelName: "roadWidthForward2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthForward2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward2",
                                                labelName: "roadWidthForward2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward3",
                                                labelName: "roadWidthForward3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthForward3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward3",
                                                labelName: "roadWidthForward3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward4",
                                                labelName: "roadWidthForward4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthForward4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_forward4",
                                                labelName: "roadWidthForward4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 車道幅員 手前 */}
                            <TableRow sx={bottomCellLabelStyle}>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　全幅5m未満の場合全幅記入</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>手前</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "roadWidthFrontLocation"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "road_width_front_location",
                                            labelName: "roadWidthFrontLocation",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location2",
                                                labelName:
                                                    "roadWidthFrontLocation2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthFrontLocation2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location2",
                                                labelName:
                                                    "roadWidthFrontLocation2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location3",
                                                labelName:
                                                    "roadWidthFrontLocation3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthFrontLocation3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location3",
                                                labelName:
                                                    "roadWidthFrontLocation3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location4",
                                                labelName:
                                                    "roadWidthFrontLocation4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "roadWidthFrontLocation4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "road_width_front_location4",
                                                labelName:
                                                    "roadWidthFrontLocation4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 坂道（長さ1ｍ当り）登 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                坂道
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　長さ1m当り
                                            </InputLabel>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　店の上手・下手各50m間の平均値
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>登</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "slopeClimb"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "slope_climb",
                                            labelName: "slopeClimb",
                                            startAdornment: "",
                                            endAdornment: "cm",
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb2",
                                                labelName: "slopeClimb2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeClimb2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb2",
                                                labelName: "slopeClimb2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb3",
                                                labelName: "slopeClimb3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeClimb3"
                                                ), //ユーザー権
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb3",
                                                labelName: "slopeClimb3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb4",
                                                labelName: "slopeClimb4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeClimb4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_climb4",
                                                labelName: "slopeClimb4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 坂道（長さ1ｍ当り）降 */}
                            <TableRow sx={bottomCellLabelStyle}>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　店の上手・下手各50m間の平均値</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>降</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "slopeDescend"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "slope_descend",
                                            labelName: "slopeDescend",
                                            startAdornment: "",
                                            endAdornment: "cm",
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend2",
                                                labelName: "slopeDescend2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeDescend2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend2",
                                                labelName: "slopeDescend2",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend3",
                                                labelName: "slopeDescend3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeDescend3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend3",
                                                labelName: "slopeDescend3",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend4",
                                                labelName: "slopeDescend4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "slopeDescend4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName: "slope_descend4",
                                                labelName: "slopeDescend4",
                                                startAdornment: "",
                                                endAdornment: "cm",
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 信号・踏切先 有無 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                信号・踏切　先
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　信号付き交差点の中は"有り"
                                                ,"0m"
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>有無</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "trafficSignalEtcForwardSelect"
                                                    )
                                                }
                                                value={
                                                    objDonation.trafficSignalEtcForwardSelect
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "trafficSignalEtcForwardSelect"
                                                          )
                                                        : donationReadOnlyObj.trafficSignalEtcForwardSelect
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "trafficSignalEtcForwardSelect"
                                                          )
                                                        : donationReadOnlyObj.trafficSignalEtcForwardSelect
                                                }
                                            >
                                                {commonYesnone?.map(
                                                    (row: any) => (
                                                        <MenuItem
                                                            key={row.code_value}
                                                            value={
                                                                row.code_value
                                                            }
                                                        >
                                                            {row.code_display}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcForwardSelect"
                                                    )}
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_forward_select",
                                                            "trafficSignalEtcForwardSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcForwardSelect"
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcForwardSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayA === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect2
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayA === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect2
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect2"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect2
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect2"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect2
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayA &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcForwardSelect2"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_forward_select2",
                                                            "trafficSignalEtcForwardSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayA &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcForwardSelect2"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayA}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcForwardSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayB === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect3
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayB === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect3
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect3"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect3
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect3"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect3
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayB &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcForwardSelect3"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_forward_select3",
                                                            "trafficSignalEtcForwardSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayB &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcForwardSelect3"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayB}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcForwardSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isBackstreet === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect4
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isBackstreet === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcForwardSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcForwardSelect4
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect4"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect4
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcForwardSelect4"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcForwardSelect4
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isBackstreet &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcForwardSelect4"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_forward_select4",
                                                            "trafficSignalEtcForwardSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isBackstreet &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcForwardSelect4"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isBackstreet}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcForwardSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>

                            {/* 信号・踏切先 距離 */}
                            <TableRow sx={bottomCellLabelStyle}>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　信号付き交差点の中は"有り" ,"0m"</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>距離</InputLabel>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: !(
                                                !setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "trafficSignalEtcForwardDistance"
                                                ) &&
                                                objDonation.trafficSignalEtcForwardSelect ===
                                                    "1"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "traffic_signal_etc_forward_distance",
                                            labelName:
                                                "trafficSignalEtcForwardDistance",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance2",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcForwardDistance2"
                                                    ) &&
                                                    objDonation.trafficSignalEtcForwardSelect2 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance2",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance3",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcForwardDistance3"
                                                    ) &&
                                                    objDonation.trafficSignalEtcForwardSelect3 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance3",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance4",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcForwardDistance4"
                                                    ) &&
                                                    objDonation.trafficSignalEtcForwardSelect4 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_forward_distance4",
                                                labelName:
                                                    "trafficSignalEtcForwardDistance4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 信号・踏切手前 有無 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                信号・踏切　手前
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　停止線から測定する
                                            </InputLabel>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　最遠200m迄
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>有無</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        <FormControl fullWidth>
                                            <Select
                                                onChange={(e) =>
                                                    handleSelectChange(
                                                        e,
                                                        "trafficSignalEtcFrontLocationSelect"
                                                    )
                                                }
                                                value={
                                                    objDonation.trafficSignalEtcFrontLocationSelect
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "trafficSignalEtcFrontLocationSelect"
                                                          )
                                                        : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "trafficSignalEtcFrontLocationSelect"
                                                          )
                                                        : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect
                                                }
                                            >
                                                {commonYesnone?.map(
                                                    (row: any) => (
                                                        <MenuItem
                                                            key={row.code_value}
                                                            value={
                                                                row.code_value
                                                            }
                                                        >
                                                            {row.code_display}
                                                        </MenuItem>
                                                    )
                                                )}
                                            </Select>
                                        </FormControl>
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcFrontLocationSelect"
                                                    )}
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_front_location_select",
                                                            "trafficSignalEtcFrontLocationSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcFrontLocationSelect"
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcFrontLocationSelect"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayA === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect2
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayA === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect2"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect2
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect2"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect2
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect2"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect2
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayA &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcFrontLocationSelect2"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_front_location_select2",
                                                            "trafficSignalEtcFrontLocationSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayA &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcFrontLocationSelect2"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayA}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcFrontLocationSelect2"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isSideWayB === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect3
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isSideWayB === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect3"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect3
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect3"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect3
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect3"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect3
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isSideWayB &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcFrontLocationSelect3"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_front_location_select3",
                                                            "trafficSignalEtcFrontLocationSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isSideWayB &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcFrontLocationSelect3"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isSideWayB}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcFrontLocationSelect3"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <Box sx={{ display: "flex" }}>
                                        {isBackstreet === true && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect4
                                                    }
                                                    sx={inactiveSelectStyle}
                                                    disabled={true}
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {isBackstreet === false && (
                                            <FormControl fullWidth>
                                                <Select
                                                    onChange={(e) =>
                                                        handleSelectChange(
                                                            e,
                                                            "trafficSignalEtcFrontLocationSelect4"
                                                        )
                                                    }
                                                    value={
                                                        objDonation.trafficSignalEtcFrontLocationSelect4
                                                    }
                                                    sx={selectStyle(
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect4"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect4
                                                    )}
                                                    disabled={
                                                        userType === "z003"
                                                            ? setPermissionFunc(
                                                                  userType,
                                                                  objLocationBase.task_id,
                                                                  LOCAL_CONSTANT
                                                                      .NAVI
                                                                      .DONATION,
                                                                  "trafficSignalEtcFrontLocationSelect4"
                                                              )
                                                            : donationReadOnlyObj.trafficSignalEtcFrontLocationSelect4
                                                    }
                                                >
                                                    {commonYesnone?.map(
                                                        (row: any) => (
                                                            <MenuItem
                                                                key={
                                                                    row.code_value
                                                                }
                                                                value={
                                                                    row.code_value
                                                                }
                                                            >
                                                                {
                                                                    row.code_display
                                                                }
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                        )}
                                        {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                        {userType === "z001" && (
                                            <Grid sx={{ display: "flex" }}>
                                                <IconButton
                                                    disabled={
                                                        !(
                                                            !isBackstreet &&
                                                            !setPermissionFunc(
                                                                userType,
                                                                objLocationBase.task_id,
                                                                LOCAL_CONSTANT
                                                                    .NAVI
                                                                    .DONATION,
                                                                "trafficSignalEtcFrontLocationSelect4"
                                                            )
                                                        )
                                                    }
                                                    onClick={() => {
                                                        handleChatBubbleClick(
                                                            "traffic_signal_etc_front_location_select4",
                                                            "trafficSignalEtcFrontLocationSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box
                                                        sx={chatBubbleCircleSx(
                                                            !(
                                                                !isBackstreet &&
                                                                !setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "trafficSignalEtcFrontLocationSelect4"
                                                                )
                                                            )
                                                        )}
                                                    >
                                                        <ChatBubbleIcon
                                                            sx={
                                                                chatBubbleIconSx
                                                            }
                                                        />
                                                    </Box>
                                                </IconButton>
                                                <IconButton
                                                    disabled={isBackstreet}
                                                    onClick={() => {
                                                        handleEditIconClick(
                                                            "trafficSignalEtcFrontLocationSelect4"
                                                        );
                                                    }}
                                                    sx={iconHover}
                                                >
                                                    <Box sx={editIconCircleSx}>
                                                        <EditIcon />
                                                    </Box>
                                                </IconButton>
                                            </Grid>
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>

                            {/* 信号・踏切手前 距離 */}
                            <TableRow sx={bottomCellLabelStyle}>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　最遠200m迄</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>距離</InputLabel>
                                        <RedAsterisk>＊＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: !(
                                                !setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "trafficSignalEtcFrontLocationDistance"
                                                ) &&
                                                objDonation.trafficSignalEtcFrontLocationSelect ===
                                                    "1"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "traffic_signal_etc_front_location_distance",
                                            labelName:
                                                "trafficSignalEtcFrontLocationDistance",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance2",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcFrontLocationDistance2"
                                                    ) &&
                                                    objDonation.trafficSignalEtcFrontLocationSelect2 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance2",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance3",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcFrontLocationDistance3"
                                                    ) &&
                                                    objDonation.trafficSignalEtcFrontLocationSelect3 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance3",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance4",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: !(
                                                    !setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "trafficSignalEtcFrontLocationDistance4"
                                                    ) &&
                                                    objDonation.trafficSignalEtcFrontLocationSelect4 ===
                                                        "1"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "traffic_signal_etc_front_location_distance4",
                                                labelName:
                                                    "trafficSignalEtcFrontLocationDistance4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 視界性 建物 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle} rowSpan={2}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                視界性
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　建物=店頭看板の"7"
                                            </InputLabel>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　サインポール=150m間5回計測
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "65px",
                                        }}
                                    >
                                        <InputLabel>建物</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "visibilityFactorBuilding"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "visibility_factor_building",
                                            labelName:
                                                "visibilityFactorBuilding",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building2",
                                                labelName:
                                                    "visibilityFactorBuilding2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorBuilding2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building2",
                                                labelName:
                                                    "visibilityFactorBuilding2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building3",
                                                labelName:
                                                    "visibilityFactorBuilding3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorBuilding3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building3",
                                                labelName:
                                                    "visibilityFactorBuilding3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building4",
                                                labelName:
                                                    "visibilityFactorBuilding4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorBuilding4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_building4",
                                                labelName:
                                                    "visibilityFactorBuilding4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 視界性 サインポール */}
                            <TableRow sx={bottomCellLabelStyle}>
                                {/* <TableCell sx={cellLabelStyle}>
                  <Grid container>
                    <Grid item xs={12}>
                      <InputLabel sx={descriptionLabelStyle}>　サインポール=150m間5回計測</InputLabel>
                    </Grid>
                  </Grid>
                </TableCell> */}
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "70px",
                                        }}
                                    >
                                        <InputLabel>
                                            サイン
                                            <br />
                                            ポール
                                        </InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "visibilityFactorSignPost"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName:
                                                "visibility_factor_sign_post",
                                            labelName:
                                                "visibilityFactorSignPost",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL.TIMES,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayA === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post2",
                                                labelName:
                                                    "visibilityFactorSignPost2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayA === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorSignPost2"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post2",
                                                labelName:
                                                    "visibilityFactorSignPost2",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isSideWayB === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post3",
                                                labelName:
                                                    "visibilityFactorSignPost3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isSideWayB === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorSignPost3"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post3",
                                                labelName:
                                                    "visibilityFactorSignPost3",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    {isBackstreet === true && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: true, //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post4",
                                                labelName:
                                                    "visibilityFactorSignPost4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                    {isBackstreet === false && (
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "visibilityFactorSignPost4"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "visibility_factor_sign_post4",
                                                labelName:
                                                    "visibilityFactorSignPost4",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.TIMES,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    )}
                                </TableCell>
                            </TableRow>

                            {/* 有効駐車場面積 */}
                            <TableRow>
                                <TableCell sx={cellLabelStyle}>
                                    <Grid container rowSpacing={2}>
                                        <Grid item xs={12}>
                                            <InputLabel sx={titleLabelStyle}>
                                                有効駐車場面積
                                            </InputLabel>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <InputLabel
                                                sx={descriptionLabelStyle}
                                            >
                                                　歩道部分除く
                                            </InputLabel>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell sx={cellLabelStyle}>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "70px",
                                        }}
                                    >
                                        <InputLabel>面積</InputLabel>
                                        <RedAsterisk>＊</RedAsterisk>
                                    </Box>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "parkingArea"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "parking_area",
                                            labelName: "parkingArea",
                                            startAdornment: "",
                                            endAdornment:
                                                LOCAL_CONSTANT.LABEL
                                                    .SQUARE_METER,
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <TextField
                                        fullWidth
                                        disabled={true}
                                        value={
                                            objDonation.parkingArea === ""
                                                ? ""
                                                : (
                                                      Math.round(
                                                          Number(
                                                              objDonation.parkingArea
                                                          ) *
                                                              0.3025 *
                                                              1000
                                                      ) / 1000
                                                  ).toFixed(2)
                                        }
                                        sx={inactiveTextSx}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start"></InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    坪
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="filled"
                                    />
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputLabel sx={{ textAlign: "center" }}>
                                        駐車可能台数
                                    </InputLabel>
                                </TableCell>
                                <TableCell sx={tableCell}>
                                    <InputWithIcons
                                        props={{
                                            userType: userType, //ユーザー
                                            disabled: setPermissionFunc(
                                                userType,
                                                objLocationBase.task_id,
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                                "parkingCapacity"
                                            ), //ユーザー権限
                                            pageName:
                                                LOCAL_CONSTANT.NAVI.DONATION,
                                            columnName: "parking_capacity",
                                            labelName: "parkingCapacity",
                                            startAdornment: "",
                                            endAdornment: "台",
                                            InputValueObj: donation,
                                            ReadOnlyObj: donationReadOnlyObj,
                                            setReadOnlyObj:
                                                setDonationReadOnlyObj,
                                            ShowEditIconObj:
                                                initDonationShowEditIconObj,
                                            ShowIconsObj: donationShowIconsObj,
                                            handleChatBubbleClick:
                                                handleChatBubbleClick,
                                            handleInputBlur: handleInputBlur,
                                            handleInputChange:
                                                handleIntOrDecimalInputChange,
                                            handleInputClick: handleInputClick,
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>

            <Grid container spacing={1} maxWidth="lg">
                <Grid item xs={12}>
                    <TableContainer>
                        <Table sx={table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell
                                        sx={tableHeaderLabelCell}
                                    ></TableCell>
                                    <TableCell
                                        sx={tableHeaderLabelCell}
                                    ></TableCell>
                                    <TableCell sx={tableHeaderCell}>
                                        <InputLabel>
                                            {LOCAL_CONSTANT.LABEL.FRONT}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={tableHeaderCell}>
                                        <InputLabel>
                                            {LOCAL_CONSTANT.LABEL.SIDE_A}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={tableHeaderCell}>
                                        <InputLabel>
                                            {LOCAL_CONSTANT.LABEL.SIDE_B}
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={tableHeaderCell}>
                                        <InputLabel>
                                            {LOCAL_CONSTANT.LABEL.BACK}
                                        </InputLabel>
                                    </TableCell>
                                </TableRow>

                                {/* 駐車場接線 */}
                                <TableRow sx={bottomCellLabelStyle}>
                                    <TableCell sx={cellLabelStyle}>
                                        <Grid container rowSpacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    sx={titleLabelStyle}
                                                >
                                                    駐車場接線
                                                </InputLabel>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell sx={cellLabelStyle}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "70px",
                                            }}
                                        >
                                            <InputLabel></InputLabel>
                                            <RedAsterisk>＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "parkingTangentLineFrontage"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "parking_tangent_line_frontage",
                                                labelName:
                                                    "parkingTangentLineFrontage",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayA === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_sideway_a",
                                                    labelName:
                                                        "parkingTangentLineSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayA === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "parkingTangentLineSideWayA"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_sideway_a",
                                                    labelName:
                                                        "parkingTangentLineSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayB === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_sideway_b",
                                                    labelName:
                                                        "parkingTangentLineSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayB === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "parkingTangentLineSideWayB"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_sideway_b",
                                                    labelName:
                                                        "parkingTangentLineSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isBackstreet === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_backstreet",
                                                    labelName:
                                                        "parkingTangentLineBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isBackstreet === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "parkingTangentLineBackstreet"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "parking_tangent_line_backstreet",
                                                    labelName:
                                                        "parkingTangentLineBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>

                                {/* 出入口No.1 進入口 */}
                                <TableRow>
                                    <TableCell sx={cellLabelStyle} rowSpan={2}>
                                        <Grid container rowSpacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    sx={titleLabelStyle}
                                                >
                                                    出入口　No.1
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    sx={descriptionLabelStyle}
                                                >
                                                    　進入口=6m以上
                                                </InputLabel>
                                                <InputLabel
                                                    sx={descriptionLabelStyle}
                                                >
                                                    　奥行=ドア前車止めの延長線上迄
                                                </InputLabel>
                                                <InputLabel
                                                    sx={descriptionLabelStyle}
                                                >
                                                    　奥行き=25度角（tan=0.466）
                                                </InputLabel>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell sx={cellLabelStyle}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "70px",
                                            }}
                                        >
                                            <InputLabel>進入口</InputLabel>
                                            <RedAsterisk>＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "no1EntranceFrontage"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "no1_entrance_frontage",
                                                labelName:
                                                    "no1EntranceFrontage",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayA === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_sideway_a",
                                                    labelName:
                                                        "no1EntranceSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayA === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1EntranceSideWayA"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_sideway_a",
                                                    labelName:
                                                        "no1EntranceSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayB === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_sideway_b",
                                                    labelName:
                                                        "no1EntranceSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayB === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1EntranceSideWayB"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_sideway_b",
                                                    labelName:
                                                        "no1EntranceSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isBackstreet === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_backstreet",
                                                    labelName:
                                                        "no1EntranceBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isBackstreet === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1EntranceBackstreet"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_entrance_backstreet",
                                                    labelName:
                                                        "no1EntranceBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>

                                {/* 出入口No.1 奥行き */}
                                <TableRow sx={bottomCellLabelStyle}>
                                    <TableCell sx={cellLabelStyle}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "70px",
                                            }}
                                        >
                                            <InputLabel>奥行き</InputLabel>
                                            <RedAsterisk>＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "no1DepthFrontage"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "no1_depth_frontage",
                                                labelName: "no1DepthFrontage",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayA === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_sideway_a",
                                                    labelName:
                                                        "no1DepthSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayA === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1DepthSideWayA"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_sideway_a",
                                                    labelName:
                                                        "no1DepthSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayB === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_sideway_b",
                                                    labelName:
                                                        "no1DepthSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayB === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1DepthSideWayB"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_sideway_b",
                                                    labelName:
                                                        "no1DepthSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isBackstreet === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_backstreet",
                                                    labelName:
                                                        "no1DepthBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isBackstreet === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no1DepthBackstreet"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no1_depth_backstreet",
                                                    labelName:
                                                        "no1DepthBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>

                                {/* 出入口No.2 進入口 */}
                                <TableRow>
                                    <TableCell sx={cellLabelStyle} rowSpan={2}>
                                        <Grid container rowSpacing={2}>
                                            <Grid item xs={12}>
                                                <InputLabel
                                                    sx={titleLabelStyle}
                                                >
                                                    出入口　No.2
                                                </InputLabel>
                                            </Grid>
                                        </Grid>
                                    </TableCell>
                                    <TableCell sx={cellLabelStyle}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85px",
                                            }}
                                        >
                                            <InputLabel>進入口</InputLabel>
                                            <RedAsterisk>＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "no2EntranceFrontage"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "no2_entrance_frontage",
                                                labelName:
                                                    "no2EntranceFrontage",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayA === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_sideway_a",
                                                    labelName:
                                                        "no2EntranceSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayA === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2EntranceSideWayA"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_sideway_a",
                                                    labelName:
                                                        "no2EntranceSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayB === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_sideway_b",
                                                    labelName:
                                                        "no2EntranceSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayB === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2EntranceSideWayB"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_sideway_b",
                                                    labelName:
                                                        "no2EntranceSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isBackstreet === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_backstreet",
                                                    labelName:
                                                        "no2EntranceBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isBackstreet === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2EntranceBackstreet"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_entrance_backstreet",
                                                    labelName:
                                                        "no2EntranceBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>

                                {/* 出入口No.2 奥行き */}
                                <TableRow>
                                    <TableCell sx={cellLabelStyle}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "85px",
                                            }}
                                        >
                                            <InputLabel>奥行き</InputLabel>
                                            <RedAsterisk>＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <InputWithIcons
                                            props={{
                                                userType: userType, //ユーザー
                                                disabled: setPermissionFunc(
                                                    userType,
                                                    objLocationBase.task_id,
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                    "no2DepthFrontage"
                                                ), //ユーザー権限
                                                pageName:
                                                    LOCAL_CONSTANT.NAVI
                                                        .DONATION,
                                                columnName:
                                                    "no2_depth_frontage",
                                                labelName: "no2DepthFrontage",
                                                startAdornment: "",
                                                endAdornment:
                                                    LOCAL_CONSTANT.LABEL.METER,
                                                InputValueObj: donation,
                                                ReadOnlyObj:
                                                    donationReadOnlyObj,
                                                setReadOnlyObj:
                                                    setDonationReadOnlyObj,
                                                ShowEditIconObj:
                                                    initDonationShowEditIconObj,
                                                ShowIconsObj:
                                                    donationShowIconsObj,
                                                handleChatBubbleClick:
                                                    handleChatBubbleClick,
                                                handleInputBlur:
                                                    handleInputBlur,
                                                handleInputChange:
                                                    handleIntOrDecimalInputChange,
                                                handleInputClick:
                                                    handleInputClick,
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayA === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_depth_sideway_a",
                                                    labelName:
                                                        "no2DepthSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayA === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2DepthSideWayA"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_depth_sideway_a",
                                                    labelName:
                                                        "no2DepthSideWayA",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isSideWayB === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    columnName:
                                                        "no2_depth_sideway_b",
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    labelName:
                                                        "no2DepthSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isSideWayB === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2DepthSideWayB"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_depth_sideway_b",
                                                    labelName:
                                                        "no2DepthSideWayB",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        {isBackstreet === true && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: true, //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_depth_backstreet",
                                                    labelName:
                                                        "no2DepthBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                        {isBackstreet === false && (
                                            <InputWithIcons
                                                props={{
                                                    userType: userType, //ユーザー
                                                    disabled: setPermissionFunc(
                                                        userType,
                                                        objLocationBase.task_id,
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                        "no2DepthBackstreet"
                                                    ), //ユーザー権限
                                                    pageName:
                                                        LOCAL_CONSTANT.NAVI
                                                            .DONATION,
                                                    columnName:
                                                        "no2_depth_backstreet",
                                                    labelName:
                                                        "no2DepthBackstreet",
                                                    startAdornment: "",
                                                    endAdornment:
                                                        LOCAL_CONSTANT.LABEL
                                                            .METER,
                                                    InputValueObj: donation,
                                                    ReadOnlyObj:
                                                        donationReadOnlyObj,
                                                    setReadOnlyObj:
                                                        setDonationReadOnlyObj,
                                                    ShowEditIconObj:
                                                        initDonationShowEditIconObj,
                                                    ShowIconsObj:
                                                        donationShowIconsObj,
                                                    handleChatBubbleClick:
                                                        handleChatBubbleClick,
                                                    handleInputBlur:
                                                        handleInputBlur,
                                                    handleInputChange:
                                                        handleIntOrDecimalInputChange,
                                                    handleInputClick:
                                                        handleInputClick,
                                                }}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>

            {/* ※検討中　未開通道路・開拓地の場合 */}
            <Grid container spacing={2} maxWidth="lg">
                <Grid item xs={8}>
                    <TableContainer>
                        <Table sx={table}>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={cellLabelStyle}>
                                        <InputLabel>
                                            ※未開通道路・開拓地の場合
                                        </InputLabel>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                width: "110px",
                                            }}
                                        >
                                            <InputLabel sx={{ width: "75px" }}>
                                                完了予定
                                            </InputLabel>
                                            <RedAsterisk>＊＊</RedAsterisk>
                                        </Box>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <FormControl fullWidth>
                                            <InputLabel id="complete-schedule-year-label">
                                                年
                                            </InputLabel>
                                            <Select
                                                labelId="complete-schedule-year-label"
                                                onChange={(e) =>
                                                    handleYearMonthSelectChange(
                                                        e,
                                                        "completeScheduleYear"
                                                    )
                                                }
                                                value={
                                                    objDonation.completeScheduleYear
                                                }
                                                input={
                                                    <OutlinedInput label="年" />
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "completeScheduleYear"
                                                          )
                                                        : donationReadOnlyObj.completeSchedule
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "completeScheduleYear"
                                                          )
                                                        : donationReadOnlyObj.completeSchedule
                                                }
                                            >
                                                {Array(27)
                                                    .fill(2024)
                                                    .map((val, i) => {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={`${
                                                                    val + i
                                                                }年`}
                                                            >{`${
                                                                val + i
                                                            } 年`}</MenuItem>
                                                        );
                                                    })}
                                                <MenuItem value="">　</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <FormControl fullWidth>
                                            <InputLabel id="complete-schedule-month-label">
                                                月
                                            </InputLabel>
                                            <Select
                                                labelId="complete-schedule-month-label"
                                                onChange={(e) =>
                                                    handleYearMonthSelectChange(
                                                        e,
                                                        "completeScheduleMonth"
                                                    )
                                                }
                                                value={
                                                    objDonation.completeScheduleMonth
                                                }
                                                input={
                                                    <OutlinedInput label="月" />
                                                }
                                                sx={selectStyle(
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "completeScheduleMonth"
                                                          )
                                                        : donationReadOnlyObj.completeSchedule
                                                )}
                                                disabled={
                                                    userType === "z003"
                                                        ? setPermissionFunc(
                                                              userType,
                                                              objLocationBase.task_id,
                                                              LOCAL_CONSTANT
                                                                  .NAVI
                                                                  .DONATION,
                                                              "completeScheduleMonth"
                                                          )
                                                        : donationReadOnlyObj.completeSchedule
                                                }
                                            >
                                                {Array(12)
                                                    .fill(1)
                                                    .map((val, i) => {
                                                        return (
                                                            <MenuItem
                                                                key={i}
                                                                value={`${
                                                                    val + i
                                                                }月`}
                                                            >{`${
                                                                val + i
                                                            } 月頃`}</MenuItem>
                                                        );
                                                    })}
                                                <MenuItem value="">　</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell sx={tableCell}>
                                        <Box sx={{ display: "flex" }}>
                                            {/* 不備コメント、編集アイコンボタン (リサーチのみ表示)*/}
                                            {userType === "z001" && (
                                                <Grid sx={{ display: "flex" }}>
                                                    <IconButton
                                                        disabled={setPermissionFunc(
                                                            userType,
                                                            objLocationBase.task_id,
                                                            LOCAL_CONSTANT.NAVI
                                                                .DONATION,
                                                            "completeSchedule"
                                                        )}
                                                        onClick={() => {
                                                            handleChatBubbleClick(
                                                                "complete_schedule",
                                                                "completeSchedule"
                                                            );
                                                        }}
                                                        sx={iconHover}
                                                    >
                                                        <Box
                                                            sx={chatBubbleCircleSx(
                                                                setPermissionFunc(
                                                                    userType,
                                                                    objLocationBase.task_id,
                                                                    LOCAL_CONSTANT
                                                                        .NAVI
                                                                        .DONATION,
                                                                    "completeSchedule"
                                                                )
                                                            )}
                                                        >
                                                            <ChatBubbleIcon
                                                                sx={
                                                                    chatBubbleIconSx
                                                                }
                                                            />
                                                        </Box>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => {
                                                            handleEditIconClick(
                                                                "completeSchedule"
                                                            );
                                                        }}
                                                        sx={iconHover}
                                                    >
                                                        <Box
                                                            sx={
                                                                editIconCircleSx
                                                            }
                                                        >
                                                            <EditIcon />
                                                        </Box>
                                                    </IconButton>
                                                </Grid>
                                            )}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item xs={4}></Grid>
            </Grid>

            {/*不備コメント モーダル画面 */}
            <DeficiencyCommentRegistModal
                props={{
                    modalOpen: deficiencyComentModalFlag,
                    setModalOpen: setDeficiencyComentModalFlag,
                    CommentTextFieldObj: donationCommentTextFieldObj,
                    columnName: columnNameStr,
                    labelName: labelNameStr,
                    handleCommentChange: handleCommentChange,
                    handleSaveComment: handleSaveComment,
                }}
            />
        </div>
    );
};

export default Donation;
